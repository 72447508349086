import React from "react";
import izevenPay from "../../../../../../assets/images/brands/izevenpay50px.png";
import {withNamespaces} from "react-i18next";
import {Card, Col, Row} from "reactstrap";

import '../Styles/hr.css';
import '../Styles/wallet.css';

const WalletCard = (props) => {
    return (
        <Card className="wallet">
            <Row className="mx-4 mt-4">
                <Col md={6} className="text-white font-size-18">

                </Col>
                <Col md={6} className="text-white font-size-22 text-end">
                    <img src={izevenPay}/>
                </Col>
            </Row>

            <strong className="text-white mb-2 mx-4 px-2" style={{ fontSize: '35px' }}>$ { props.wallet.amount_available }<small>USD</small> </strong>

            <hr className="hr-wallet mx-3"/>

            <Row className="text-white mb-3 mt-3">
                <Col className="text-center">
                    <i className="fa fa-wallet fa-3x mb-3"/>
                    <p>{props.t('ID Wallet')}: { props.wallet.code }</p>
                </Col>
            </Row>
        </Card>
    )
}

export default withNamespaces()(WalletCard);
