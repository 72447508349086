import {useEffect, useRef, useState} from "react";
import {withNamespaces} from "react-i18next";
import {
    Button,
    Col,
    FormGroup,
    FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import React from "react";
import Select from "react-select";
import {axiosApi} from "../../../../../../helpers/Api";
import {useSelector} from "react-redux";
import convertToBase64 from "../../../../../../helpers/Convert/base64";

const Create = (props) => {

    const {user} = useSelector((state) => state.user);

    const [data, setData] = useState({
        market_id: '',
        asset_id: '',
        takeProfits: [],
        stock_loss: '',
        stop_loss_pips: '',
        analysis_link: '',
        description: '',
        image: '',
        type: '',
        educator_id: ''
    });

    const [takeProfit, setTakeProfit] = useState('');
    const [pip, setPip] = useState('');

    const [markets, setMarkets] = useState([]);
    const [assets, setAssets] = useState([]);
    const [educators, setEducators] = useState([]);

    const imageRef = useRef();

    /**
     * @desc Method to get markets.
     * @returns {Promise<void>}
     */
    const getMarkets = async () => {
        await axiosApi.get(process.env.REACT_APP_SIGNALS_API + 'api/markets')
            .then(response => {
               setMarkets(response.data.map((market) => {
                   return { label: market.name, value: market.id, assets: market.assets }
               }));
            })
            .catch(error => console.error(error));
    }

    const getEducators = async () => {
        await axiosApi.get(process.env.REACT_APP_SIGNALS_API + 'api/educators')
            .then(response => {
                setEducators(response.data.map((educator) => {
                    return { label: educator.name, value: educator.id }
                }));
            })
            .catch(error => console.log(error));
    }

    /**
     * @desc Method to manage the inputs fields
     * @param event
     * @param name
     */
    const handleInputChange = (event, name = null) => {
        setData({
            ...data,
            [name ?? event.target.name]: name ? event.value : event.target.value
        })
    }

    /**
     * @desc Method to upload the image.
     * @param e
     */
    const handleFile = (e) => {
        const file = e.target.files[0];
        convertToBase64(file)
            .then((response) => {
                setData({
                    ...data,
                    image: response
                });
            });
    }

    /**
     * @desc Method to add take profit to the list.
     */
    const addTP = () => {
        let tmp = [...data.takeProfits, { takeProfit: takeProfit, pip: pip }];
        setData({
            ...data,
            takeProfits: tmp
        });
        setTakeProfit('');
        setPip('');
    }

    /**
     * @desc Method to remove take profit of the list.
     * @param index
     */
    const removeTP = (index) => {
        let tmp = [...data.takeProfits];
        tmp.splice(index, 1);
        setData({
            ...data,
            takeProfits: tmp
        });
    }

    const validate = () => !(data.market_id && data.asset_id && data.takeProfits.length && data.stock_loss && data.type);

    /**
     * @desc Method to launch the signal.
     * @returns {Promise<void>}
     */
    const save = async () => {
        await axiosApi.post(process.env.REACT_APP_SIGNALS_API + 'api/signals', data)
            .then(response => {
                props.toggle();
                props.getSignals();
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        if (data.market_id) {
            setAssets(markets.filter(market => market.value === data.market_id)[0].assets.map(asset => {
                return { label: asset.asset, value: asset.id };
            }));
        }
    }, [data.market_id]);

    useEffect(() => {
        getMarkets();
        getEducators();
    }, []);

    return (
        <Modal size="lg" centered toggle={props.toggle} isOpen={props.isOpen}>
            <ModalHeader charCode="Y" toggle={props.toggle}/>
            <ModalBody>
                <h3 className="mb-3 text-primary">{props.t('Add Signal')}</h3>
                <hr/>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Educator')}</Label>
                    <Select name="educator" value={data.educator} options={educators}
                            onChange={(e) => handleInputChange(e, "educator_id")} placeholder="Select the educator of the signal..."/>
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Market')}</Label>
                    <Select name="market" value={data.market} options={markets}
                            onChange={(e) => handleInputChange(e, "market_id")} placeholder="Select the market of the signal..."/>
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Asset')}</Label>
                    <Select isDisabled={!data.market_id} name="assets" value={data.asset} options={assets}
                            onChange={(e) => handleInputChange(e, "asset_id")} placeholder="Select the asset of the signal..."/>
                </FormGroup>
                <Row>
                    <Col xs={5} md={5}>
                        <FormGroup floating className="mb-1">
                            <Label>{props.t('Take Profits')}</Label>
                            <Input type="number" name="takeProfit" value={takeProfit} onChange={(e) => setTakeProfit(e.target.value)}/>
                        </FormGroup>
                    </Col>
                    <Col xs={5} md={5}>
                        <FormGroup floating className="mb-1">
                            <Label>{props.t('Pips')}</Label>
                            <Input type="number" name="takeProfit" value={pip} onChange={(e) => setPip(e.target.value)}/>
                        </FormGroup>
                    </Col>
                    <Col xs={2} md={2}>
                        <FormGroup>
                            <Label style={{color: 'white'}}>{props.t('Add')}</Label> <br/>
                            <Button onClick={addTP} color="primary" className="btn-rounded"> <i className="fa fa-plus"/></Button>
                        </FormGroup>
                    </Col>
                </Row>
                <Table bordered>
                    {
                        data.takeProfits.map((tp, index) =>
                            <tr className="text-center">
                                <td className="fw-bold" style={{width: '30%'}}>Take Profit { index + 1 }</td>
                                <td>{ tp.takeProfit }</td>
                                <td>{ tp.pip }</td>
                                <td style={{width: "10%", cursor: 'pointer'}}><i onClick={() => removeTP(index)} className="fa fa-trash text-danger"/></td>
                            </tr>
                        )
                    }
                </Table>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Stop Loss')}</Label>
                    <Input type="number" name="stock_loss" value={data.stock_loss} onChange={handleInputChange}/>
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Stop Loss Pips')}</Label>
                    <Input type="number" name="stop_loss_pips" value={data.stop_loss_pips} onChange={handleInputChange}/>
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Analysis Link')}</Label>
                    <Input type="string" name="analysis_link" value={data.analysis_link} onChange={handleInputChange}/>
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Description')}</Label>
                    <Input type="textarea" rows={5} name="description" value={data.description} onChange={handleInputChange}/>
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Sell or Buy?')}</Label>
                    <Select name="market" value={data.market}
                            options={[{label: "Sell", value: "Sell"}, {label: "Buy", value: "Buy"}]}
                            onChange={(e) => handleInputChange(e, "type")} placeholder="This signal is for...."/>
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Order Type')}</Label>
                    <Select name="market" value={data.market}
                            options={[{label: "Sell Limit", value: "Sell Limit"}, {label: "Buy Limit", value: "Buy Limit"}, {label: "Market Execution", value: "Market Execution"}]}
                            onChange={(e) => handleInputChange(e, "order_type")} placeholder="This signal is for...."/>
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Entrance Price')}</Label>
                    <Input type="number" name="entrance_price" value={data.entrance_price} onChange={handleInputChange}/>
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Current Price')}</Label>
                    <Input type="number" name="current_price" value={data.current_price} onChange={handleInputChange}/>
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{ props.t('Image') }</Label>
                    <Row className="mx-1">
                        <Button onClick={() => imageRef.current.click()} color="primary" outline={!data.image}> { !data.image ? <><i className="fa fa-upload"/> Upload Image</> : <><i className="fa fa-check"/> Image Uploaded</> }</Button>
                        <input type="file" ref={imageRef} accept="image/png, image/gif, image/jpeg" onChange={(e) => handleFile(e)} hidden/>
                    </Row>
                    { data.image ? <FormText>To change the uploaded click again.</FormText> : <></> }
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.toggle} color="secondary">{props.t('Cancel')}</Button>
                <Button onClick={save} disabled={validate()} color="primary">{props.t('Create')}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default withNamespaces()(Create);
