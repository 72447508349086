import React from "react";
import {withNamespaces} from "react-i18next";
import {Button, Card, CardBody, Col, Row} from "reactstrap";

const General = (props) => {

    let {project} = props;

    const plusvalue = () => {
        const current = project.current.total_value
        const initial = project.initial.total_value

        return ((current - initial) * 100) / initial
    }

    const progress = () => {
        return ((project.current.available_capital_units - project.capital_units) * 100) / project.capital_units
    }

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col md={6}>
                        <h5 className="text-primary mb-1"><i className="fa fa-building-o"></i> Type:</h5>
                        <p>{project.type.name}</p>
                    </Col>
                    <Col md={6}>
                        <h5 className="text-primary mb-1"><i className="fas fa-map-marker"></i> Ubication:</h5>
                        <p>{
                            project.ubication.country + ', ' + project.ubication.state + ', ' + project.ubication.city
                        }</p>
                    </Col>
                    <Col md={12}>
                        <h5 className="text-primary mb-1">Description:</h5>
                        <div dangerouslySetInnerHTML={{__html: project.description}}/>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={4}>
                        <h5 className="text-primary mb-1">Total Value:</h5>
                        <p>${project.current.total_value}</p>
                    </Col>
                    <Col md={4}>
                        <h5 className="text-primary mb-1">Capital Unit Value:</h5>
                        <p>${project.current.capital_unit_value}</p>
                    </Col>
                    <Col md={4}>
                        <h5 className="text-primary mb-1">Plusvalue:</h5>
                        <p>{plusvalue()} %</p>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={4}>
                        <h5 className="text-primary mb-1">Selled Units:</h5>
                        <p>{project.current.available_capital_units - project.capital_units} / {project.capital_units}</p>
                    </Col>
                    <Col md={8}>
                        <h5 className="text-primary mb-1">Selled Units:</h5>
                        <div className="progress">
                            <div className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                 role="progressbar" aria-valuenow={progress()} aria-valuemin="0"
                                 aria-valuemax="100"></div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    {
                        project.contract ?
                            <a href={ project.contract } target="_blank" class="btn btn-primary btn-block">Download Contract</a> : <></>
                    }
                </Row>
            </CardBody>
        </Card>
    )
}

export default withNamespaces()(General);
