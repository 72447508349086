import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Card, CardBody, CardHeader, Collapse, Table} from "reactstrap";
import {Link} from "react-router-dom";
import Level from "../Components/Level";

const Lessons = (props) => {

    const {course} = props;

    return (
        <>
            <h3 className="mb-3 text-primary">{ props.t('Lessons') }</h3>
            <hr/>

            <div id="accordion">
                {
                    course.levels.map(level => (
                        <Level getCourse={props.getCourse} level={level} lessons={level.lessons} />
                    ))
                }
            </div>
        </>
    );
}

export default withNamespaces()(Lessons);
