import React, {useEffect, useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ISelect from "../../../../components/Select";
import {axiosApi} from "../../../../helpers/Api";

const ActiveSubscription = (props) => {

    const [subscription, setSubscription] = useState(null);
    const [payCommission, setPayCommission] = useState(null);
    const [pointsUp, setPointsUp] = useState(null);

    const [plans, setPlans] = useState([]);
    const getPlans = async () => {
        await axiosApi.get(process.env.REACT_APP_IZEVEN_URL + 'api/plans')
            .then(response => {
                setPlans(response.data.map(element => ({label: element.name, value: element.id})));
            })
            .catch(error => {
                console.error(error);
            })
    }

    // Active the user subscription
    const activeSubscription = async () => {
        let data = {
            plan_id: subscription,
            email: props.user.email,
            withPoints: pointsUp === 'true',
            withCommission: payCommission === 'true'
        }

        await axiosApi.post(process.env.REACT_APP_IZEVEN_URL + 'api/users/activate', data)
            .then(response => {
                props.toggle();
                props.getUsers();
            })
            .catch(error => console.log(error));
    }

    // Enable button.
    const enabled = () => {
        return !(subscription && pointsUp && payCommission)
    }

    // Clean State
    const clean = () => {
        setSubscription(null);
        setPayCommission(null);
        setPointsUp(null);
    }

    useEffect(() => {
        getPlans();
    }, []);


    return (
        <Modal centered onOpened={clean} toggle={props.toggle} isOpen={props.isOpen}>
            <ModalHeader charCode="Y" toggle={props.toggle}/>
            <ModalBody>
                <h3 className="mb-3 text-primary">{props.t('Activate Subscription')}</h3>
                <hr/>
                <Form>
                    <FormGroup floating className="mb-1">
                        <Label>{props.t('Subscription')}</Label>
                        <ISelect value={subscription} onChange={(e) => setSubscription(e.value)} options={plans} placeholder="Select plan..." />
                    </FormGroup>
                </Form>
                <hr/>
                <p>{props.t('Pay commission?')}</p>
                <Form onChange={(e) => setPayCommission(e.target.value) }>
                    <FormGroup
                        check
                        inline
                    >
                        <Input value={true} name="link-side"  type="radio" />
                        <Label radioGroup className="mb-0">
                            {props.t('Yes')}
                        </Label>
                    </FormGroup>
                    <FormGroup
                        check
                        inline
                    >
                        <Input value={false} name="link-side"  type="radio" />
                        <Label radioGroup className="mb-0">
                            {props.t('No')}
                        </Label>
                    </FormGroup>
                </Form>
                <hr/>
                <p>{props.t('Points up?')}</p>
                <Form onChange={(e) => setPointsUp(e.target.value) }>
                    <FormGroup
                        check
                        inline
                    >
                        <Input value={true} name="link-side"  type="radio" />
                        <Label radioGroup className="mb-0">
                            {props.t('Yes')}
                        </Label>
                    </FormGroup>
                    <FormGroup
                        check
                        inline
                    >
                        <Input value={false} name="link-side"  type="radio" />
                        <Label radioGroup className="mb-0">
                            {props.t('No')}
                        </Label>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button onClick={activeSubscription} disabled={ enabled() } color="primary">{props.t('Activate')}</Button>
            </ModalFooter>
        </Modal>
    );
}

export default withNamespaces()(ActiveSubscription);
