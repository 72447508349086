import React, {useEffect, useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Col, FormGroup, Input, Label, Row, Table} from "reactstrap";
import {axiosApi} from "../../../../helpers/Api";
import {save} from "react-bootstrap-table2-toolkit/lib/src/csv/exporter";

const Answers = (props) => {

    const [answers, setAnswers] = useState([]);

    const [answer, setAnswer] = useState('');

    const [isCorrect, setIsCorrect] = useState(false);
    const toggle = () => setIsCorrect(!isCorrect);

    const getAnswers = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/test/answers/' + props.question.id)
            .then(response => {
                setAnswers(response.data);
            })
            .catch(error => console.error(error));
    }

    const add = async () => {

        let data = {
            question_id: props.question.id,
            correct: isCorrect,
            answer: answer
        }

        await axiosApi.post(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/test/answers', data)
            .then(response => {
                getAnswers();
            })
            .catch(error => console.error(error));

        props.getQuestions()
    }

    const deleteAnswer = async (id) => {
        await axiosApi.delete(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/test/answers/' + id)
            .then(response => {
                getAnswers();
            })
            .catch(error => console.error(error));

        props.getQuestions();
    }

    useEffect(() => {
        if (props.question) {
            getAnswers();
        }
    }, [props.question])

    return (
        <>
            <h3 className="text-primary"> <i style={{ cursor: "pointer" }} onClick={() => props.change('questions')} className="fa fa-arrow-left" /> Answers of { props.question ? props.question.question : '' }</h3>
            <hr/>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>{props.t('Answer ')}</Label>
                        <Input placeholder="Type the answer..." type="text" value={answer} onChange={(e) => setAnswer(e.target.value)} />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup className="text-center">
                        <Label className="text-white">{props.t('Answer')}</Label>
                        <div className="form-check form-switch" style={{ margin: "10px" }} dir="ltr">
                            <Input onChange={toggle} type="checkbox" className="form-check-input" id="correct" />
                            <Label className="form-check-label" htmlFor="correct">Correct Answer</Label>
                        </div>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <p className="mb-2 text-white">{props.t('File')} </p>
                        <Button onClick={add} style={{ width: "100%" }} block color="primary"> <i className="fa fa-check"/> Save Answer</Button>
                    </FormGroup>
                </Col>
            </Row>
            <Table bordered responsive>
                <thead>
                <tr className="text-primary text-uppercase">
                    <th>
                        Answer
                    </th>
                    <th style={{width: "5%"}}>Correct</th>
                    <th style={{width: "5%"}}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {
                    answers.map(answer => (
                        <tr>
                            <td>{ answer.answer }</td>
                            <td className="text-center">
                                {
                                    answer.correct ? <i className="fa fa-circle text-success"/> : <i className="fa fa-circle text-danger"/>
                                }
                            </td>
                            <td className="text-center"><i style={{ cursor: "pointer" }} onClick={() => deleteAnswer(answer.id)} className="fa fa-times text-danger"/></td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        </>
    );
}

export default withNamespaces()(Answers);
