import React from "react";
import { withNamespaces } from "react-i18next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {  
  Container,  
} from "reactstrap";
import Transactions from "./components/Transactions";
import Points from "./components/Points";

const Reports = (props) => {  
  let breadcrumbItems = [
    { title: "Odin", link: "/" },
    { title: "Izeven", link: "/izeven" },
    { title: "Reports", link: "#" },
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Reports" breadcrumbItems={breadcrumbItems} />
        <Transactions />
        <Points />
      </Container>
    </div>
  );
};

export default withNamespaces()(Reports);
