import React, {useEffect, useState} from "react";
import {withNamespaces} from "react-i18next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {Col, Container, Row} from "reactstrap";
import Form from "./Components/Form";
import History from "./Components/History";
import {axiosApi} from "../../../helpers/Api";

const Masterclass = (props) => {

    let breadcrumbItems = [
        {title: "Odin", link: "/"},
        {title: "Mindpro", link: "/mindpro"},
        {title: "Masterclass", link: "#"}
    ];


    const [classes, setClasses] = useState([]);

    const getClasses = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/masterclasses')
            .then(response => {
                setClasses(response.data);
            })
            .catch(error => console.log(error));
    }

    useEffect(() => {
        getClasses();
    }, []);


    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Masterclass" breadcrumbItems={breadcrumbItems}/>

                <Row>
                    <Col md={6}>
                        <Form getClasses={getClasses} />
                    </Col>
                    <Col md={6}>
                        <History getClasses={getClasses} classes={classes} />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default withNamespaces()(Masterclass);
