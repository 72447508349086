import React, {useEffect, useState} from "react";
import {withNamespaces} from "react-i18next";
import {axiosApi} from "../../../../../helpers/Api";
import {Card, CardBody, Col, Row, Table} from "reactstrap";
import {Link} from "react-router-dom";
import {formatDate} from "../../../../../utils/format";
import Swal from "sweetalert2";
import toastr from "toastr";

const Blocks = (props) => {

    let {project} = props;

    const [blocks, setBlocks] = useState([]);
    const [pendingBlocks, setPendingBlocks] = useState([]);

    const getBlocks = () => {
        axiosApi.get(process.env.REACT_APP_AGRONOMY_URL + 'api/blocks/project/' + project.id + '/Approved')
            .then(response => {
                setBlocks(response.data.blocks);
            });
    }

    const getPendingBlocks = () => {
        axiosApi.get(process.env.REACT_APP_AGRONOMY_URL + 'api/blocks/project/' + project.id + '/Pending')
            .then(response => {
                setPendingBlocks(response.data.blocks);
            });
    }

    /**
     * @desc Method to approve block
     * @param id
     */
    const approve = (id) => {
        Swal.fire({
            title: 'Confirm Approval',
            text: 'Are you sure you approve this block?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#3f9ce8",
            confirmButtonText: "Yes, Continue",
            cancelButtonText: "No, Cancel"
        })
            .then(result => {
                if (result.isConfirmed) {
                    axiosApi.post(process.env.REACT_APP_AGRONOMY_URL + 'api/blocks/approve/' + id)
                        .then(response => {
                            toastr.success(props.t('Block approved with success.'), 'Success');
                            getBlocks();
                            getPendingBlocks();
                        })
                        .catch(error => {
                            toastr.error(props.t('Server error, try again later.'), 'Error');
                            console.log(error);
                        })
                }
            });
    }

    /**
     * @desc Method to approve block
     * @param id
     */
    const refuse = (id) => {
        Swal.fire({
            title: 'Confirm Refuse',
            text: 'Are you sure you refues this block?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#3f9ce8",
            confirmButtonText: "Yes, Continue",
            cancelButtonText: "No, Cancel"
        })
            .then(result => {
                if (result.isConfirmed) {
                    axiosApi.post(process.env.REACT_APP_AGRONOMY_URL + 'api/blocks/refuse/' + id)
                        .then(response => {
                            toastr.success(props.t('Block refused with success.'), 'Success');
                            getBlocks();
                            getPendingBlocks();
                        })
                        .catch(error => {
                            toastr.error(props.t('Server error, try again later.'), 'Error');
                            console.log(error);
                        })
                }
            });
    }

    useEffect(() => {
        getBlocks();
        getPendingBlocks();
    }, [])

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col md={12}>
                        <h3 className="text-primary">Blocks</h3>
                        <hr/>
                        <Table responsive>
                            <thead>
                            <tr>
                                <th>{props.t('id')}</th>
                                <th>{props.t('Buyer')}</th>
                                <th>{props.t('Capital Units')}</th>
                                <th>{props.t('Status')}</th>
                                <th>{props.t('Contract')}</th>
                                <th>{props.t('Payment')}</th>
                                <th>{props.t('Created At')}</th>
                                <th>{props.t('Updated At')}</th>
                                <th>{props.t('Actions')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                pendingBlocks.map(block => (
                                    <tr>
                                        <td>{block.id}</td>
                                        <td>{block.user ? block.user.name : ''}</td>
                                        <td>{block.capital_units}</td>
                                        <td>{block.status}</td>
                                        <td>
                                            {
                                                block.contract ?
                                                    <a className="btn btn-block btn-primary"
                                                       href={block.contract.path}
                                                       target="_blank">
                                                        See Contract
                                                    </a> : <></>
                                            }
                                        </td>
                                        <td>
                                            {
                                                block.transfer ?
                                                    <a className="btn btn-block btn-primary"
                                                       href={block.transfer.path}
                                                       target="_blank">
                                                        See Transfer
                                                    </a> : <></>
                                            }
                                        </td>
                                        <td>
                                            { formatDate(block.created_at) }
                                        </td>
                                        <td>
                                            { formatDate(block.updated_at) }
                                        </td>
                                        <td>
                                            <div className="btn-group">
                                                <button onClick={() => approve(block.id)} type="button" className="btn btn-sm btn-primary js-tooltip-enabled">
                                                    <i className="fas fa-check"></i> {'  '} Approve
                                                </button>
                                                <button onClick={() => refuse(block.id)} type="button" className="btn btn-sm btn-danger js-tooltip-enabled">
                                                    <i className="fa fa-times"></i> {'  '} Reject
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <h3 className="text-primary">Blocks Approved</h3>
                        <hr/>
                        <Table responsive>
                            <thead>
                            <tr>
                                <th>{props.t('Id')}</th>
                                <th>{props.t('Buyer')}</th>
                                <th>{props.t('Capital Units')}</th>
                                <th>{props.t('Status')}</th>
                                <th>{props.t('Contract')}</th>
                                <th>{props.t('Payment')}</th>
                                <th>{props.t('Created At')}</th>
                                <th>{props.t('Updated At')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                blocks.map(block => (
                                    <tr>
                                        <td>{block.id}</td>
                                        <td>{block.user ? block.user.name : ''}</td>
                                        <td>{block.capital_units}</td>
                                        <td>{block.status}</td>
                                        <td>
                                            {
                                                block.contract ?
                                                    <a className="btn btn-block btn-primary"
                                                       href={block.contract.path}
                                                       target="_blank">
                                                        See Contract
                                                    </a> : <></>
                                            }
                                        </td>
                                        <td>
                                            {
                                                block.transfer ?
                                                    <a className="btn btn-block btn-primary"
                                                       href={block.transfer.path}
                                                       target="_blank">
                                                        See Transfer
                                                    </a> : <></>
                                            }
                                        </td>
                                        <td>
                                            { formatDate(block.created_at) }
                                        </td>
                                        <td>
                                            { formatDate(block.updated_at) }
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default withNamespaces()(Blocks);
