import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Col, FormGroup, Input, Label, Row, Table} from "reactstrap";
import {axiosApi} from "../../../../helpers/Api";

const Questions = (props) => {

    const [question, setQuestion] = useState('');

    const change = (question) => {
        props.setQuestion(question);
        props.change();
    }

    const add = async () => {

        let data = {
            test_id: props.quiz,
            question: question
        }

        await axiosApi.post(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/test/questions', data)
            .then(response => {
                props.getQuestions();
            })
            .catch(error => console.error(error));
    }

    const deleteQuestion = async (id) => {
        await axiosApi.delete(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/test/questions/' + id)
            .then(response => {
                props.getQuestions();
            })
            .catch(error => console.error(error));
    }

    return (
        <>
            <h3 className="text-primary">Questions</h3>
            <hr/>
            <Row>
                <Col md={9}>
                    <FormGroup>
                        <Label>{props.t('Question')}</Label>
                        <Input placeholder="Type the question..." type="text" value={question} onChange={(e) => setQuestion(e.target.value)} />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <p className="mb-2 text-white">{props.t('File')} </p>
                        <Button onClick={add} style={{ width: "100%" }} block color="primary"> <i className="fa fa-check"/> Save Question</Button>
                    </FormGroup>
                </Col>
            </Row>
            <Table bordered responsive>
                <thead>
                <tr className="text-primary text-uppercase">
                    <th>
                        Question
                    </th>
                    <th style={{width: "5%"}}>Answers</th>
                    <th style={{width: "5%"}}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.questions.map(question => (
                        <tr>
                            <td className="text-primary" style={{ cursor: "pointer" }} onClick={() => change(question)} >{ question.question }</td>
                            <td className="text-center">{ question.answers.length }</td>
                            <td className="text-center"><i style={{ cursor: "pointer" }} onClick={() => deleteQuestion(question.id)} className="fa fa-times text-danger"/></td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        </>
    );
}

export default withNamespaces()(Questions);
