import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Card, CardBody, Form, FormGroup, FormText, Input, Label} from "reactstrap";

const Admon = (props) => {

    const [total, setTotal] = useState('');
    const [units, setUnits] = useState('');

    return (
        <Card>
            <CardBody>
                <h4 className="text-primary">Admon</h4>
                <p>In this section you can admin the project values. <strong className="text-danger">BE CAREFUL</strong>.</p>
                <hr/>

                <Form>
                    <FormGroup>
                        <Label>{props.t('Total Value')}</Label>
                        <Input type="number" value={total} onChange={(e) => setTotal(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label>{props.t('Capital Units')}</Label>
                        <Input type="number" value={units} onChange={(e) => setUnits(e.target.value)} />
                        <FormText>The number of capital units cannot be modified in the future and the value of each unit will be defined by dividing the total value of the project by the number of capital units.</FormText>
                    </FormGroup>
                </Form>
            </CardBody>
        </Card>
    )
}

export default withNamespaces()(Admon);
