import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Container, Row, Table} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {axiosApi} from "../../helpers/Api";
import {Link} from "react-router-dom";
import {formatDate} from "../../utils/format";
import {withNamespaces} from "react-i18next";

const Iup = (props) => {

    let breadcrumbItems = [
        {title: "Odin", link: "/"},
        {title: "iUP", link: "#"},
    ];

    const [documents, setDocuments] = useState([]);

    const getDocuments = async () => {
        await axiosApi.get(process.env.REACT_APP_IZEVEN_URL + 'api/iup')
            .then(({data}) => {
                setDocuments(data);
            })
            .catch(err => console.error(err));
    }

    useEffect(() => {
        getDocuments();
    }, []);


    return (
        <>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="iUP" breadcrumbItems={breadcrumbItems}/>

                    <Card>
                        <div className="px-4 py-4">
                            <Table responsive>
                                <thead className="table-light">
                                <tr>
                                    <th>{props.t('Name')}</th>
                                    <th>{props.t('Balance')}</th>
                                    <th>{props.t('Signed Document')}</th>
                                    <th>{props.t('Date')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    documents.map(document => (
                                        <tr>
                                            <td>{document.user.name}</td>
                                            <td>{document.balance}</td>
                                            <td><a href={document.signed_document} target="_blank" className="btn btn-primary"><i className="fa fa-eye"/> See Document</a></td>
                                            <td>{ formatDate(document.created_at) }</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </div>
                    </Card>

                </Container>
            </div>
        </>
    )
}

export default withNamespaces()(Iup);
