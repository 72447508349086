import React, {useEffect, useState} from "react";
import {withNamespaces} from "react-i18next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
    Card,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row, TabContent, TabPane
} from "reactstrap";
import {axiosApi} from "../../../helpers/Api";
import {useParams} from "react-router-dom";
import Details from "./Sections/Details";
import Lessons from "./Sections/Lessons";
import classnames from "classnames";
import PrivateMentorings from "./Sections/PrivateMentorings";

const Course = (props) => {

    let {id} = useParams();
    const [course, setCourse] = useState({});
    const [loading, setLoading] = useState(true);

    const [activeTab, setActiveTab] = useState('details');
    const toggleTab = (id) => setActiveTab(id);

    let breadcrumbItems = [
        {title: "Odin", link: "/"},
        {title: "Mindpro", link: "/mindpro"},
        {title: "Courses", link: "/mindpro/courses"},
        {title: course.title ?? '', link: "#"}
    ];

    const getCourse = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/course/' + id)
            .then(response => {
                setCourse(response.data);
                setLoading(false);
            })
            .catch(error => console.log(error));
    }

    useEffect(() => {
        getCourse();
    }, [])

    return (
        <div className="page-content">
            {
                !loading ?
                    <Container fluid>
                        <Breadcrumbs title={ course.title ?? '' } breadcrumbItems={breadcrumbItems}/>

                        <Row>
                            <Col md={12}>
                                <Nav tabs className="nav-tabs-custom nav-justified">
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "details"
                                            })}
                                            onClick={() => {
                                                toggleTab("details");
                                            }}
                                        >
                                            <span className="d-sm-block">Details</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "mentorings"
                                            })}
                                            onClick={() => {
                                                toggleTab("mentorings");
                                            }}
                                        >
                                            <span className="d-sm-block">Private Mentorings</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>

                        <Card>
                            <Row className="mt-2">
                                <Col md={12}>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="details" className="p-3">
                                            <Row>
                                                <Col md={5}>
                                                    <Details getCourse={getCourse} course={course} />
                                                </Col>
                                                <Col md={7}>
                                                    <Lessons getCourse={getCourse} course={course} />
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="mentorings" className="p-3">
                                            <PrivateMentorings course={course} />
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                        </Card>
                    </Container>     : <></>
            }
        </div>
    )
}

export default withNamespaces()(Course);
