import React, {useRef, useState} from "react";
import {withNamespaces} from "react-i18next";
import convertToBase64 from "../../../../helpers/Convert/base64";
import {Button, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {axiosApi} from "../../../../helpers/Api";

const Create = (props) => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [picture, setPicture] = useState('');
    const [signature, setSignature] = useState('');

    const pictureRef = useRef();
    const signatureRef = useRef();

    const handleChange = (e, setter) => {
        const file = e.target.files[0];
        convertToBase64(file)
            .then(response => {
                setter(response);
            })
    }

    const save = async () => {

        let data = {
            name: name,
            email: email,
            picture: picture,
            signature: signature
        }

        await axiosApi.post(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/educator', data)
            .then(response => {
                props.getEducators();
                props.toggle();
            })
            .catch(error => console.log(error.message));
    }

    const validate = () => {
        return !name && !email && !picture && !signature;
    }

    return (
        <Modal centered toggle={props.toggle} isOpen={props.isOpen}>
            <ModalHeader charCode="Y" toggle={props.toggle}/>
            <ModalBody>
                <h3 className="mb-3 text-primary">{props.t('Add Educator')}</h3>
                <hr/>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Image')}</Label>
                    <Row className="mx-1">
                        <Button onClick={() => pictureRef.current.click()} color="primary" outline={!picture}> { !picture ? <><i className="fa fa-upload"/> Upload Image</> : <><i className="fa fa-check"/> Image Uploaded</> }</Button>
                        <input type="file" ref={pictureRef} accept="image/png, image/gif, image/jpeg" onChange={(e) => handleChange(e, setPicture)} hidden/>
                    </Row>
                    { picture ? <FormText>To change the uploaded click again.</FormText> : <></> }
                </FormGroup>
                <br/>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Name')}</Label>
                    <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                </FormGroup>
                <br/>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Email')}</Label>
                    <Input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                </FormGroup>
                <br/>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Signature')}</Label>
                    <Row className="mx-1">
                        <Button onClick={() => signatureRef.current.click()} color="primary" outline={!signature}>{ !signature ? <><i className="fa fa-upload"/> Upload Signature</> : <><i className="fa fa-check"/> Image Uploaded</> }</Button>
                        <input type="file" ref={signatureRef} accept="image/png, image/gif, image/jpeg" onChange={(e) => handleChange(e, setSignature)} hidden/>
                    </Row>
                    { signature ? <FormText>To change the uploaded click again.</FormText> : <></> }
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button onClick={save} disabled={validate()} color="primary">{props.t('Add')}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default withNamespaces()(Create);
