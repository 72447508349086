import React, {useEffect, useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Col, FormGroup, Label, Row, Table} from "reactstrap";
import {formatDate} from "../../../../../utils/format";
import Pagination from "../../../../../components/Common/Pagination";
import NoMatches from "../../../../../components/NoMatches";
import {axiosApi} from "../../../../../helpers/Api";

const Categories = (props) => {

    const [categories, setCategories] = useState([]);

    const [create, setCreate] = useState(false);
    const toggleCreate = () => setCreate(!create);

    const getCategories = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/recorded-lessons/categories')
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        getCategories();
    }, [])

    return (
        <div className="">
            <Row className="p-4 justify-content-end">
                <Col md={4}>
                    <FormGroup>
                        <Label style={{color: 'white'}}>Add New</Label>
                        <Button onClick={toggleCreate} style={{width: "100%"}} color="primary"> <i
                            className="fa fa-plus"/> Add New</Button>
                    </FormGroup>
                </Col>
            </Row>

            {
                categories.length ?
                    <>
                        <div className="px-4">
                            <Table responsive>
                                <thead className="table-light">
                                <tr>
                                    <th>#</th>
                                    <th>{props.t('Name')}</th>
                                    <th>{props.t('Description')}</th>
                                    <th>{props.t('Actions')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    categories.map((category, index) => (
                                        <tr key={index}>
                                            <td>{category.id}</td>
                                            <td>{category.name}</td>
                                            <td>{category.description}</td>
                                            <td><i className="fa fa-edit text-primary"/>{'  '}<i
                                                className="fa fa-trash text-danger"/></td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </div>
                    </> : <NoMatches/>
            }
        </div>
    )
}

export default withNamespaces()(Categories);
