import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import WalletCard from "./Components/WalletCard";
import { Col, Row } from "reactstrap";
import Transactions from "./Components/Transactions";
import { axiosApi } from "../../../../../helpers/Api";

const Wallet = (props) => {
  const [wallet, setWallet] = useState(null);

  const getWallet = () => {
    axiosApi
      .get(
        process.env.REACT_APP_IZEVEN_URL + "api/users/wallet/" + props.user.id
      )
      .then((response) => {
        setWallet(response.data.wallet.wallet);
      });
  };

  useEffect(() => {
    getWallet();
  }, []);

  return (
    <Row className="justify-content-center">
      {wallet ? (
        <>
          <Col md={6}>
            <WalletCard wallet={wallet} />
          </Col>
          <Col md={12}>
            <Transactions
              user={props.user}
              wallet={wallet}
              getWallet={getWallet}
              transactions={wallet.transactions}
            />
          </Col>
        </>
      ) : (
        <></>
      )}
    </Row>
  );
};

export default withNamespaces()(Wallet);
