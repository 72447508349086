import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { axiosApi } from "../../../../../../../helpers/Api";
import toastr from "toastr";

const PersonalData = (props) => {
  const [name, setName] = useState(props.user.name ?? "");
  const [email, setEmail] = useState(props.user.email ?? "");
  const [identification, setIdentification] = useState(
    props.user.complete_info
      ? props.user.complete_info.identification_number
      : ""
  );
  const [birthday, setBirthday] = useState(
    props.user.complete_info ? props.user.complete_info.birthday : ""
  );
  const [phone, setPhone] = useState(
    props.user.complete_info ? props.user.complete_info.phone : ""
  );

  const update = () => {
    let data = {
      user_id: props.user.id,
      name: name,
      email: email,
      identification_number: identification,
      birthday: birthday,
      phone: phone,
    };

    axiosApi
      .put(
        process.env.REACT_APP_IZEVEN_URL + "api/users/update-personal-data",
        data
      )
      .then((response) => {
        alert("User updated successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Row>
      <Col md={12}>
        <h3 className="text-primary">Personal Info</h3>
        <hr />
        <Form>
          <FormGroup>
            <Label>{props.t("Name")}</Label>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              id="name"
              type="text"
            />
          </FormGroup>

          <FormGroup>
            <Label>{props.t("Email")}</Label>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              type="text"
            />
          </FormGroup>

          <FormGroup>
            <Label>{props.t("Identification Number")}</Label>
            <Input
              value={identification}
              onChange={(e) => setIdentification(e.target.value)}
              id="identification"
              type="text"
            />
          </FormGroup>

          <FormGroup>
            <Label>{props.t("Birthdate")}</Label>
            <Input
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
              id="birthday"
              type="text"
            />
          </FormGroup>

          <FormGroup>
            <Label>{props.t("Phone Number")}</Label>
            <Input
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              id="phone"
              type="text"
            />
          </FormGroup>

          <Button
            onClick={update}
            color="primary"
            className="w-md waves-effect waves-light"
          >
            {props.t("Update")}
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

export default withNamespaces()(PersonalData);
