import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {axiosApi} from "../../../../helpers/Api";

const Lesson = (props) => {

    const [title, setTitle] = useState('');

    const save = async () => {
        let data = {
            level_id: props.levelId,
            title: title,
        }

        await axiosApi.post(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/lesson', data)
            .then(response => {
                props.getCourse();
            })
            .catch(error => console.error(error));

        props.toggle();
    }

    const validate = () => {
        return !title;
    }

    return (
        <Modal centered toggle={props.toggle} isOpen={props.isOpen}>
            <ModalHeader charCode="Y" toggle={props.toggle}>
                <span className="text-primary">Add Lesson</span>
            </ModalHeader>
            <ModalBody>
                <FormGroup floating className="mb-1">
                    <Label>{ props.t('Title') }</Label>
                    <Input type="text" value={title} onChange={(e) => setTitle(e.target.value)}/>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button onClick={save} disabled={validate()} color="primary">{props.t('Add')}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default withNamespaces()(Lesson);
