import React, {useEffect, useRef, useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Card, CardBody, Col, FormGroup, Input, Label, Row, Table} from "reactstrap";
import convertToBase64 from "../../../../helpers/Convert/base64";
import {axiosApi} from "../../../../helpers/Api";

const Resources = (props) => {

    const [resources, setResources] = useState([]);
    const [title, setTitle] = useState('');
    const [file, setFile] = useState('');

    const buttonRef = useRef();

    const handleChange = (e) => {
        const file = e.target.files[0]
        convertToBase64(file)
            .then(response => {
                setFile(response);
            })
    }

    const getResources = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/lesson/resources/' + props.lesson.id)
            .then(response => {
                setResources(response.data);
            })
            .catch(error => console.error(error));
    }

    const addResource = async () => {
        const data = {
            title: title,
            file: file,
            lesson_id: props.lesson.id
        }

        await axiosApi.post(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/lesson/resources', data)
            .then(response => {
                setTitle('');
                setFile('');
                getResources();
            })
            .catch(error => console.error(error))
    }

    const deleteResource = async (id) => {
        await axiosApi.delete(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/lesson/resources/' + id)
            .then(response => {
                getResources();
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        getResources();
    }, []);


    return (
        <Card>
            <CardBody>
                <h3 className="text-primary">Resources</h3>
                <hr/>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label>{props.t('Title')}</Label>
                            <Input type="text" value={title} onChange={(e) => setTitle(e.target.value)}/>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <p style={{color: "#74788d"}} className="mb-2">{props.t('File')} <small>(jpg, jpeg, png,
                                pdf, doc, ppt, xls)</small></p>
                            <Button onClick={() => buttonRef.current.click()} style={{width: "100%"}} block
                                    outline={!file} color="primary">{file ? <><i className="fa fa-check"/> Click to
                                change file</> : 'Upload file'}</Button>
                            <input onChange={(e) => handleChange(e)} ref={buttonRef} type="file"
                                   style={{display: 'none'}}/>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <p className="mb-2 text-white">{props.t('Title')}</p>
                            <Button onClick={addResource} style={{width: "100%"}} block color="primary">Add</Button>
                        </FormGroup>
                    </Col>
                </Row>
                <hr/>
                <Table bordered>
                    <thead>
                    <tr className="text-primary text-uppercase">
                        <th>Title</th>
                        <th style={{width: "5%"}}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        resources.map(resource => (
                            <tr>
                                <td><a href={resource.url} target="_blank">{ resource.title }</a></td>
                                <td className="text-center">
                                    <i style={{ cursor: "pointer" }}  onClick={() => deleteResource(resource.id)} className="fa fa-times text-danger"/>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
}

export default withNamespaces()(Resources);
