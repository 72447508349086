import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Card, CardBody, Col, Row, Table} from "reactstrap";
import Transaction from "./Transaction";

const Transactions = (props) => {

    const [transaction, setTransaction] = useState(false);
    const toggle = () => setTransaction(!transaction);

    return (
        <>
            <Row className="mt-4">
                <Col className="mb-4" sm={12} style={{ textAlign: 'right' }}>
                    <Button onClick={toggle} color="primary" className="fw-bold btn-rounded"> <i className="fa fa-plus"/> Add Transaction</Button>
                </Col>
                <Col sm={12}>
                    <Table responsive>
                        <thead className="table-light">
                        <tr>
                            <th className="izeven-text">{props.t('DATE')}</th>
                            <th className="izeven-text">{props.t('Description')}</th>
                            <th className="izeven-text">{props.t('Additional Info')}</th>
                            <th className="izeven-text">{props.t('Amount')}</th>
                            <th className="izeven-text">{props.t('Status')}</th>
                            <th className="izeven-text">{props.t('Balance')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            props.transactions.map(transaction => (
                                <tr>
                                    <th>{transaction.date}</th>
                                    <td>{transaction.description}</td>
                                    <td>{transaction.additional_info}</td>
                                    {transaction.type > -1 ? <td className="text-success"> $ {transaction.amount}</td> :
                                        <td className="text-danger">$ {transaction.amount}</td>}
                                    <td>{transaction.status}</td>
                                    <td>{transaction.balance}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Transaction user={props.user} wallet={props.wallet} getWallet={props.getWallet} isOpen={transaction} toggle={toggle} />
        </>
    )
}

export default withNamespaces()(Transactions);
