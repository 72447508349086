import React, {Component, useState} from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

//i18n
import { withNamespaces } from "react-i18next";

// users
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../../features/userSlice";
import {useHistory} from "react-router-dom";

const ProfileMenu = (props) => {

    const { user: currentUser } = useSelector((state) => state.user);

    const dispatch = useDispatch();

    const history = useHistory();

    const singOut = (e) => {
        e.preventDefault()
        dispatch(logout())
            .then(() => window.location = '/login')
    };

    const [menu, setMenu] = useState(false);
    const toggle = () => setMenu(!menu);

    return (
        <>
            <React.Fragment>
                <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block user-dropdown">
                    <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
                        <span className="d-none d-xl-inline-block ms-1 text-transform">{ currentUser.name }</span>
                        <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"/>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem className="text-danger" href="/logout"><i className="ri-shut-down-line align-middle me-1 text-danger"/> {props.t('Logout')}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        </>
    )
}

export default withNamespaces()(ProfileMenu);
