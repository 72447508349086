import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Swal from "sweetalert2";
import {axiosApi} from "../../../../helpers/Api";
import toastr from "toastr";

const Reject = (props) => {

    const [message, setMessage] = useState('');

    /**
     * @desc Method to reject user kyc
     * @param id
     */
    const rejectKyc = () => {
        Swal.fire({
            title: 'Reject Kyc',
            text: 'Are you sure you reject this user\'s kyc?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#3f9ce8",
            confirmButtonText: "Yes, Continue",
            cancelButtonText: "No, Cancel"
        })
            .then(result => {
                if (result.isConfirmed) {
                    axiosApi.post(process.env.REACT_APP_IZEVEN_URL + 'api/kyc/reject/' + props.id, { message: message })
                        .then(response => {
                            toastr.success(props.t('Kyc rejected with success.'), 'Success');
                            props.toggle();
                        })
                        .catch(error => {
                            toastr.success(props.t('Server error, try again later.'), 'Success');
                            console.log(error);
                        })
                }
            });
    }

    return (
        <Modal centered toggle={props.toggle} isOpen={props.isOpen}>
            <ModalHeader charCode="Y" toggle={props.toggle}/>
            <ModalBody>
                <h3 className="mb-3 text-primary">{props.t('Reject KYC')}</h3>
                <hr/>
                <Form>
                    <FormGroup floating className="mb-1">
                        <Label>{props.t('Reason for rejection')}</Label>
                        <Input value={message} onChange={(e) => setMessage(e.target.value)} id="message" type="textarea" />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button onClick={rejectKyc} color="primary">{props.t('Send')}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default withNamespaces()(Reject);
