import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {axiosApi} from "../../../../../../../helpers/Api";
import toastr from "toastr";

const BankInfo = (props) => {

    const [name, setName] = useState(props.user.bank_info ? props.user.bank_info.bank_name : '');
    const [country, setCountry] = useState(props.user.bank_info ? props.user.bank_info.bank_country : '');
    const [state, setState] = useState(props.user.bank_info ? props.user.bank_info.bank_state : '');
    const [city, setCity] = useState(props.user.bank_info ? props.user.bank_info.bank_city : '');
    const [swift, setSwift] = useState(props.user.bank_info ? props.user.bank_info.swift_code : '');
    const [type, setType] = useState(props.user.bank_info ? props.user.bank_info.account_type : '');
    const [number, setNumber] = useState(props.user.bank_info ? props.user.bank_info.account_number : '');
    const [tax, setTax] = useState(props.user.bank_info ? props.user.bank_info.tax_id : '');

    const update = (e) => {
        e.preventDefault();
        let data = {
            user_id: props.user.id,
            name: name,
            country: country,
            state: state,
            city: city,
            swift_code: swift,
            account_type: type,
            account_number: number,
            tax_id: tax,
        }

        axiosApi.put(process.env.REACT_APP_IZEVEN_URL + 'api/users/update-bank-info', data)
            .then(response => {
                alert('User updated successfully');
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <Row>
            <Col md={12}>
                <h3 className="text-primary">Bank Info</h3>
                <hr/>
                <Form>
                    <FormGroup>
                        <Label>{props.t('Bank Name')}</Label>
                        <Input value={name} onChange={(e) => setName(e.target.value)} id="bank_name" type="text" />
                    </FormGroup>

                    <FormGroup>
                        <Label>{props.t('Bank Country')}</Label>
                        <Input value={country} onChange={(e) => setCountry(e.target.value)} id="bank_country" type="text" />
                    </FormGroup>

                    <FormGroup>
                        <Label>{props.t('Bank State')}</Label>
                        <Input value={state} onChange={(e) => setState(e.target.value)} id="bank_state" type="text" />
                    </FormGroup>

                    <FormGroup>
                        <Label>{props.t('Bank City')}</Label>
                        <Input value={city} onChange={(e) => setCity(e.target.value)} id="bank_city" type="text" />
                    </FormGroup>

                    <FormGroup>
                        <Label>{props.t('Swift Code')}</Label>
                        <Input value={swift} onChange={(e) => setSwift(e.target.value)} id="swift" type="text" />
                    </FormGroup>

                    <FormGroup>
                        <Label>{props.t('Account Type')}</Label>
                        <Input value={type} onChange={(e) => setType(e.target.value)} id="type" type="text" />
                    </FormGroup>

                    <FormGroup>
                        <Label>{props.t('Account Number')}</Label>
                        <Input value={number} onChange={(e) => setNumber(e.target.value)} id="number" type="text" />
                    </FormGroup>

                    <FormGroup>
                        <Label>{props.t('Tax ID')}</Label>
                        <Input value={tax} onChange={(e) => setTax(e.target.value)} id="tax" type="text" />
                    </FormGroup>

                    <Button onClick={update} color="primary" className="w-md waves-effect waves-light">{props.t('Update')}</Button>
                </Form>
            </Col>
        </Row>
    )
}

export default withNamespaces()(BankInfo);
