import React from "react";
import {withNamespaces} from "react-i18next";
import {Button, Card, CardBody, Table} from "reactstrap";
import {axiosApi} from "../../../../helpers/Api";

const History = (props) => {

    const endMasterclass = async (id) => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/masterclass/end/' + id)
            .then(response => {
                props.getClasses();
            })
            .catch(error => console.error(error));
    }

    return (
        <Card>
            <CardBody>
                <h3 className="mb-3 text-primary">{props.t('History')}</h3>
                <hr/>
                <Table responsive>
                    <thead className="table-light">
                    <tr>
                        <th>Title</th>
                        <th>Educators</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.classes.map((element) =>
                            <tr style={{verticalAlign: "middle"}}>
                                <td>{ element.title }</td>
                                <td> { element.educators.map((educator, index) => educator.name + (index === element.educators.length -1 ? '' : ', ')  ) }</td>
                                <td>
                                    {
                                        element.status === 'Active' ? <Button onClick={() => endMasterclass(element.id)} color="danger">Terminar</Button> : <></>
                                    }
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
}

export default withNamespaces()(History);
