import {
    Button,
    Col,
    FormGroup,
    FormText,
    Input,
    Label, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import Select from "react-select";
import React, {useState} from "react";
import {axiosApi} from "../../../../../../helpers/Api";
import {withNamespaces} from "react-i18next";

const ChangeStatus = (props) => {

    const { signal } = props;

    const [status, setStatus] = useState({ label: signal.status, value: signal.status });
    const signalsStatus = [
        { label: 'Active', value: 'Active' },
        { label: 'Pending', value: 'Pending' },
        { label: 'Canceled', value: 'Canceled' },
        { label: 'Won', value: 'Won' },
        { label: 'Lost', value: 'Lost' },
    ];

    const [pips, setPips] = useState(0);

    const changeStatus = async () => {
        await axiosApi.patch(process.env.REACT_APP_SIGNALS_API + 'api/signals/change_status/' + signal.id, { status: status.value, pips: pips })
            .then(response => {
                props.getSignals();
                props.toggle();
            })
            .catch(error => console.error(error));
    }

    return (
        <Modal size="lg" centered toggle={props.toggle} isOpen={props.isOpen}>
            <ModalHeader charCode="Y" toggle={props.toggle}/>
            <ModalBody>
                <h3 className="mb-3 text-primary">{props.t('Change Signal Status')}</h3>
                <hr/>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Status')}</Label>
                    <Select name="status" value={status} options={signalsStatus} onChange={(e) => setStatus(e)} placeholder="Select the new status of the signal..."/>
                </FormGroup>
                {
                    status.label === 'Won' || status.label === 'Lost' ?
                        <FormGroup floating className="mb-1">
                            <Label>{props.t('Pips')}</Label>
                            <Input type="number" name="pips" value={pips} onChange={(e) => setPips(e.target.value)}/>
                        </FormGroup> : <></>
                }
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.toggle} color="secondary">{props.t('Cancel')}</Button>
                <Button onClick={changeStatus} color="primary">{props.t('Update')}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default withNamespaces()(ChangeStatus);
