import React, {useEffect, useState} from "react";
import {Button, Card, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Table} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {axiosApi} from "../../../helpers/Api";
import {withNamespaces} from "react-i18next";
import Pagination from "../../../components/Common/Pagination";
import {Link} from "react-router-dom";
import NoMatches from "../../../components/NoMatches";

const Network = (props) => {

    const [users, setUsers] = useState([]);
    const [pages, setPages] = useState([]);
    const [ranks, setRanks] = useState([]);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [rank, setRank] = useState('');
    const [currentRank, setCurrentRank] = useState('');

    /**
     * @desc Method to get all users.
     */
    const getUsers = (page = null) => {
        const url = page === null ? process.env.REACT_APP_NETWORK_SERVICE_URL + 'api/all_users' : page;
        axiosApi.post(url, {email: email, name: name, rank: rank, current_rank: currentRank})
            .then(response => {
                setUsers(response.data.data);
                setPages(response.data.links)
            });
    }

    const getRanks = async () => {
        axiosApi.get(process.env.REACT_APP_NETWORK_SERVICE_URL + 'api/ranks')
            .then(response => {
               setRanks(response.data);
            });
    }

    let breadcrumbItems = [
        {title: "Odin", link: "/"},
        {title: "Izeven", link: "/izeven"},
        {title: "Users", link: "#"},
    ];

    useEffect(() => {
        getUsers();
        getRanks();
    }, []);

    return (
        <>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="Users" breadcrumbItems={breadcrumbItems}/>

                    <Card>
                        <Row className="p-4">
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{props.t('Search by Name')}</Label>
                                    <InputGroup>
                                        <Input value={name} onChange={(e) => setName(e.target.value)} id="name"
                                               type="name"/>
                                        <Button onClick={() => getUsers()} color="primary">
                                            <i className="fa fa-search"/>
                                        </Button>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{props.t('Search by Email')}</Label>
                                    <InputGroup>
                                        <Input value={email} onChange={(e) => setEmail(e.target.value)} id="email"
                                               type="email"/>
                                        <Button onClick={() => getUsers()} color="primary">
                                            <i className="fa fa-search"/>
                                        </Button>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{props.t('Filter by Rank')}</Label>
                                    <InputGroup>
                                        <Input value={rank} onChange={(e) => setRank(e.target.value)} id="rank" type="select">
                                            <option></option>
                                            {
                                                ranks.map((rank, i) => (
                                                    <option key={i} value={rank.id}>{rank.name}</option>
                                                ))
                                            }
                                        </Input>
                                        <Button onClick={() => getUsers()} color="primary">
                                            <i className="fa fa-search"/>
                                        </Button>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{props.t('Filter by Month Rank')}</Label>
                                    <InputGroup>
                                        <Input value={currentRank} onChange={(e) => setCurrentRank(e.target.value)} id="current_rank" type="select">
                                            <option></option>
                                            {
                                                ranks.map((rank, i) => (
                                                    <option key={i} value={rank.id}>{rank.name}</option>
                                                ))
                                            }
                                        </Input>
                                        <Button onClick={() => getUsers()} color="primary">
                                            <i className="fa fa-search"/>
                                        </Button>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>

                        {
                            users.length ?
                                <>
                                    <div className="px-4">
                                        <Table responsive>
                                            <thead className="table-light">
                                            <tr>
                                                <th>{props.t('Id')}</th>
                                                <th>{props.t('Name')}</th>
                                                <th>{props.t('Email')}</th>
                                                <th>{props.t('Plan')}</th>
                                                <th>{props.t('Rank')}</th>
                                                <th>{props.t('Month Rank')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                users.map((user, index) =>
                                                    <tr key={index}>
                                                        <td>{user.id}</td>
                                                        <td>
                                                            <Link to={'/izeven/users/' + user.id}>{user.name}</Link>
                                                        </td>
                                                        <td>{user.email}</td>
                                                        <td>{user.subscription ?  user.subscription : props.t('No Suscribed')}</td>
                                                        <td>{user.node.rank.name}</td>
                                                        <td>{user.node.current_rank.name}</td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </Table>
                                    </div>

                                    <Row>
                                        <Col md={12}>
                                            <Pagination pages={pages} getMethod={getUsers}/>
                                        </Col>
                                    </Row>
                                </> : <NoMatches />

                        }
                    </Card>

                </Container>
            </div>
        </>
    )
}

export default withNamespaces()(Network);
