import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ISelect from "../../../../../../components/Select";
import {axiosApi} from "../../../../../../helpers/Api";
import toastr from "toastr";

const Transaction = (props) => {

    const [date, setDate] = useState(null);
    const [description, setDescription] = useState(null);
    const [additionalInfo, setAdditionalInfo] = useState(null);
    const [type, setType] = useState(null);
    const [amount, setAmount] = useState(null);

    const create = () => {
        let data = {
            user_id: props.user.id,
            date: date,
            description: description,
            additional_info: additionalInfo,
            type: type,
            amount: amount
        }

        axiosApi.post(process.env.REACT_APP_WALLET_SERVICE_URL + 'api/transactions/create', data)
            .then(response => {
                toastr.success(props.t('Transaction created with success.'), 'Success');
                props.getWallet();
                props.toggle();
            })
            .catch(error => {
                console.log(error);
            })
    }

    // Enable button.
    const enabled = () => {
        return !(date && description && additionalInfo && type && amount)
    }

    return (
        <Modal centered toggle={props.toggle} isOpen={props.isOpen}>
            <ModalHeader charCode="Y" toggle={props.toggle}/>
            <ModalBody>
                <h3 className="mb-3 text-primary">{props.t('Create Transaction')}</h3>

                <FormGroup floating className="mb-2">
                    <Label>{props.t('Date')}</Label>
                    <Input value={date} onChange={(e) => setDate(e.target.value)} id="expiration_date" type="date"/>
                </FormGroup>

                <FormGroup floating className="mb-2">
                    <Label>{props.t('Description')}</Label>
                    <Input value={description} onChange={(e) => setDescription(e.target.value)} id="description"
                           type="text"/>
                </FormGroup>

                <FormGroup floating className="mb-2mb-2">
                    <Label>{props.t('Additional Info')}</Label>
                    <Input value={additionalInfo} onChange={(e) => setAdditionalInfo(e.target.value)}
                           id="additional-info" type="text"/>
                </FormGroup>

                <FormGroup floating className="mb-2">
                    <Label>{props.t('Type')}</Label>
                    <ISelect value={type} onChange={(e) => setType(e.value)}
                             options={[{label: 'Income (1)', value: 1}, {label: 'Egress (-1)', value: -1}]}
                             placeholder="Select type of transaction..."/>
                </FormGroup>

                <FormGroup floating className="mb-2">
                    <Label>{props.t('Amount')}</Label>
                    <Input value={amount} onChange={(e) => setAmount(e.target.value)} id="amount" type="number" />
                </FormGroup>

            </ModalBody>
            <ModalFooter>
                <Button onClick={create} disabled={enabled()}  color="primary">{props.t('Create')}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default withNamespaces()(Transaction);
