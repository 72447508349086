import React, {useEffect, useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Card, Col, Container, Row, Table} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Create from "./Components/Create";
import {axiosApi} from "../../../helpers/Api";
import {formatDate} from "../../../utils/format";

const Educators = (props) => {

    const [educators, setEducators] = useState([]);

    const [add, setAdd] = useState(false);
    const toggle = () => setAdd(!add);

    let breadcrumbItems = [
        {title: "Odin", link: "/"},
        {title: "Mindpro", link: "/mindpro"},
        {title: "Educators", link: "#"}
    ];

    const getEducators = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/educators')
            .then(response => {
                setEducators(response.data);
            })
            .catch(error => console.log(error));
    }

    useEffect(() => {
        getEducators();
    }, []);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Educators" breadcrumbItems={breadcrumbItems}/>

                    <Card>
                        <div className="px-4 py-4">

                            <Row className="justify-content-end mb-4">
                                <Col md={2} className="text-end">
                                    <Button className="btn btn-primary btn-rounded" block color="primary" onClick={toggle}> <i className="fas fa-plus"/> Add New</Button>
                                </Col>
                            </Row>

                            <Table responsive>
                                <thead className="table-light">
                                <tr>
                                    <th style={{ width: "8%" }}>{props.t('Image')}</th>
                                    <th>{props.t('Name')}</th>
                                    <th>{props.t('Created at')}</th>
                                    <th>{props.t('Signature')}</th>
                                    <th>{props.t('Actions')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    educators.map(educator => (
                                        <tr style={{ verticalAlign: 'middle' }}>
                                            <td>
                                                <img src={educator.image} className="rounded-circle" width="50" height="50" />
                                            </td>
                                            <td>
                                                { educator.name }
                                            </td>
                                            <td>{ formatDate(educator.created_at) }</td>
                                            <td>
                                                <img src={educator.signature} width="100" />
                                            </td>
                                            <td>
                                                <div className="btn-group">
                                                    <Row>
                                                        <Col md={6}>
                                                            <i className="fas fa-edit text-primary"></i>
                                                        </Col>
                                                        <Col md={6}>
                                                            <i className="fas fa-trash text-danger"></i>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </div>
                    </Card>
                </Container>
            </div>

            <Create getEducators={getEducators} isOpen={add} toggle={toggle} />
        </>
    )
}

export default withNamespaces()(Educators);
