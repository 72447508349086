import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "reactstrap";
import Questions from "./Components/Questions";
import Answers from "./Components/Answers";
import {axiosApi} from "../../../helpers/Api";

const Index = (props) => {

    const [questions, setQuestions] = useState([]);

    const [page, setPage] = useState('questions');
    const change = (page) => setPage(page);

    const [question, setQuestion] = useState({});

    const getQuestions = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/test/questions/' + props.quiz.id)
            .then(response => {
                setQuestions(response.data);
            })
            .catch(error => console.error(error));
    }

    return (
        <Modal onOpened={getQuestions} size="xl" centered toggle={props.toggle} isOpen={props.isOpen}>
            <ModalHeader charCode="Y" toggle={props.toggle}>
                <span className="text-primary">Quiz</span>
            </ModalHeader>
            <ModalBody>
                {
                    page === 'questions' ? <Questions quiz={props.quiz.id} questions={questions} getQuestions={getQuestions} change={change} setQuestion={setQuestion} /> : <Answers question={question} change={change} getQuestions={getQuestions} />
                }
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.toggle} color="primary" outline >{props.t('Close')}</Button>
            </ModalFooter>
        </Modal>
    );
}

export default withNamespaces()(Index);
