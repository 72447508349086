import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";

const TetherWallet = (props) => {

    const [wallet, setWallet] = useState(props.user.tether_wallet ? props.user.tether_wallet.address : '');

    return (
        <Row>
            <Col md={12}>
                <h3 className="text-primary">Tether Wallet</h3>
                <hr/>
                <FormGroup>
                    <Label>{props.t('Network')}</Label>
                    <h5>TRON (TRC20)</h5>
                </FormGroup>
                <FormGroup>
                    <Label>{props.t('Wallet Address')}</Label>
                    <Input value={wallet} onChange={(e) => setWallet(e.target.value)} id="name" type="text" />
                </FormGroup>
                <Button color="primary" className="w-md waves-effect waves-light">{props.t('Update')}</Button>
            </Col>
        </Row>
    );
}

export default withNamespaces()(TetherWallet);
