import {withNamespaces} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Card, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import SignalsSection from "./Sections/Signals/index";
import classnames from "classnames";
import Markets from "./Sections/Markets";

const Signals = (props) => {

    const [signals, setSignals] = useState([]);

    const [create, setCreate] = useState(false);
    const toggle = () => setCreate(!create);


    const [activeTab, setActiveTab] = useState('signals');
    const toggleTab = (id) => setActiveTab(id);

    let breadcrumbItems = [
        {title: "Odin", link: "/"},
        {title: "Signals", link: "#"}
    ];


    const getSignals = () => {

    }

    useEffect(() => {

    }, []);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Signals" breadcrumbItems={breadcrumbItems}/>

                    <Row>
                        <Col md={12}>
                            <Nav tabs className="nav-tabs-custom nav-justified">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTab === "signals"
                                        })}
                                        onClick={() => {
                                            toggleTab("signals");
                                        }}
                                    >
                                        <span className="d-sm-block">Signals</span>
                                    </NavLink>
                                </NavItem>
                                {/*<NavItem>*/}
                                {/*    <NavLink*/}
                                {/*        style={{ cursor: "pointer" }}*/}
                                {/*        className={classnames({*/}
                                {/*            active: activeTab === "markets"*/}
                                {/*        })}*/}
                                {/*        onClick={() => {*/}
                                {/*            toggleTab("markets");*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        <span className="d-sm-block">Markets</span>*/}
                                {/*    </NavLink>*/}
                                {/*</NavItem>*/}
                            </Nav>
                        </Col>
                    </Row>

                    <Card>
                        <Col md={12}>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="signals" className="p-3">
                                    <SignalsSection />
                                </TabPane>
                                <TabPane tabId="markets" className="p-3">
                                    <Markets />
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Card>
                </Container>
            </div>
        </>
    )
}

export default withNamespaces()(Signals);
