import {useEffect, useRef, useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "reactstrap";
import React from "react";
import Select from "react-select";
import Tab from "bootstrap/js/src/tab";
import {axiosApi} from "../../../../../../helpers/Api";
import convertToBase64 from "../../../../../../helpers/Convert/base64";

const Edit = (props) => {

    const [data, setData] = useState({
        name: '',
        assets: [],
    });

    const [asset, setAsset] = useState('');
    const [assetIcon, setAssetIcon] = useState('');

    /**
     * @desc Method to manage the inputs fields
     * @param event
     * @param name
     */
    const handleInputChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }

    /**
     * @desc Method to add asset.
     */
    const addAsset = () => {
        let tmp = [...data.assets, { asset: asset, icon: assetIcon }];
        setData({
            ...data,
            assets: tmp
        });
        setAsset('');
        setAssetIcon('');
    }

    /**
     * @desc Method to remove asset from the list of assets.
     * @param index
     */
    const removeAsset = (index) => {
        let tmp = [...data.assets];
        tmp.splice(index, 1);
        setData({
            ...data,
            assets: tmp
        });
    }

    const validate = () => {
        return !(data.name && data.assets.length);
    }

    /**
     * @desc Method to save the market.
     * @returns {Promise<void>}
     */
    const save = async () => {
        await axiosApi.put(process.env.REACT_APP_SIGNALS_API + 'api/markets/' + props.market.id, data)
            .then((response) => {
                props.toggle();
                props.getMarkets();
            })
    }

    const marketIcon = useRef();
    const assetRef = useRef();

    const handleFileAsset = (e) => {
        const file = e.target.files[0];
        convertToBase64(file)
            .then((response) => {
                setAssetIcon(response);
            });
    }

    /**
     * @desc Method to upload the image.
     * @param e
     */
    const handleFile = (e) => {
        const file = e.target.files[0];
        convertToBase64(file)
            .then((response) => {
                setData({
                    ...data,
                    icon: response
                });
            });
    }

    useEffect(() => {
        let d = {
            name: props.market.name,
            assets: props.market.assets,
        }

        setData(d);
    }, [props]);


    return (
        <Modal size="lg" centered toggle={() => props.toggle()} isOpen={props.isOpen}>
            <ModalHeader charCode="Y" toggle={() => props.toggle()}/>
            <ModalBody>
                <h3 className="mb-3 text-primary">{ props.t('Add Market') }</h3>
                <hr/>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Name')}</Label>
                    <Input type="text" name="name" value={data.name} onChange={handleInputChange}/>
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Icon')}</Label><br/>
                    <input type="file" ref={marketIcon} accept="image/png, image/gif, image/jpeg" onChange={(e) => handleFile(e)} hidden/>
                    <Button onClick={() => marketIcon.current.click()} color="primary" outline={!data.icon}>
                        { !data.icon ? <><i className="fa fa-upload"/> Upload Image</> : <><i className="fa fa-check"/> Image Uploaded</> }
                    </Button>
                </FormGroup>
                <Row>
                    <Col xs={5} md={6}>
                        <FormGroup floating className="mb-1">
                            <Label>{props.t('Assets')}</Label>
                            <Input type="text" name="asset" value={asset} onChange={(e) => setAsset(e.target.value)}/>
                        </FormGroup>
                    </Col>
                    <Col xs={5} md={5}>
                        <FormGroup>
                            <Label>{props.t('Icon')}</Label> <br/>
                            <input type="file" ref={assetRef} accept="image/png, image/gif, image/jpeg" onChange={(e) => handleFileAsset(e)} hidden/>
                            <Button onClick={() => assetRef.current.click()} className="btn-rounded" color="primary" outline={!assetIcon}>
                                { !assetIcon ? <><i className="fa fa-upload"/> Upload Image</> : <><i className="fa fa-check"/> Image Uploaded</> }
                            </Button>
                        </FormGroup>
                    </Col>
                    <Col xs={2} md={1}>
                        <FormGroup>
                            <Label style={{ color: 'white' }}>{props.t('Add')}</Label> <br/>
                            <Button color="primary" className="btn-rounded" onClick={addAsset}><i className="fa fa-plus"/></Button>
                        </FormGroup>
                    </Col>
                </Row>
                <Table bordered>
                    {
                        data.assets.map((asset, index) => (
                            <tr className="text-center">
                                <td className="fw-bold" style={{ width: '30%' }}>Asset { index + 1 }</td>
                                <td>{ asset.asset }</td>
                                <td>
                                    <img src={asset.icon} />
                                </td>
                                <td style={{ width: "10%"}}><i style={{ cursor: "pointer" }} className="fa fa-trash text-danger" onClick={removeAsset} /></td>
                            </tr>
                        ))
                    }
                </Table>
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => props.toggle()} color="secondary">{props.t('Cancel')}</Button>
                <Button disabled={validate()} color="primary" onClick={save}>{props.t('Create')}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default withNamespaces()(Edit);
