import React, {useEffect, useState} from "react";
import {withNamespaces} from "react-i18next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {Button, Card, CardBody, Col, Container, Form, FormGroup, FormText, Input, Label, Row, Table} from "reactstrap";
import {axiosApi} from "../../../helpers/Api";
import Add from "./Components/Add";

const Calendar = (props) => {

    const [events, setEvents] = useState([]);

    const [add, setAdd] = useState(false);
    const toggle = () => setAdd(!add);

    const getVideos = () => {
        axiosApi.get(process.env.REACT_APP_AVANX_API + 'api/events')
            .then(response => {
                setEvents(response.data.events);
            });
    }

    let breadcrumbItems = [
        {title: "Odin", link: "/"},
        {title: "Avanx", link: "/avanx"},
        {title: "Calendar", link: "/avanx/calendar"},
    ];

    useEffect(() => {
        getVideos();
    }, []);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Calendar" breadcrumbItems={breadcrumbItems}/>

                    <Row>
                        <Card>
                            <CardBody>

                                <Row className="justify-content-end mx-1">
                                    <Col md={2}>
                                        <Row>
                                            <Button onClick={toggle} color="primary" className="fw-bold btn-rounded" block> <i className="fas fa-plus"/> Add </Button>
                                        </Row>
                                    </Col>
                                </Row>

                                <Table responsive className="mt-4">
                                    <thead className="table-light">
                                    <tr>
                                        <th>{props.t('Title')}</th>
                                        <th>{props.t('Speaker')}</th>
                                        <th>{props.t('Date')}</th>
                                        <th>{props.t('Time')}</th>
                                        <th>{props.t('Actions')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        events.map(event =>
                                            <tr>
                                                <td>{ event.title }</td>
                                                <td>{ event.speaker }</td>
                                                <td>{ event.date }</td>
                                                <td>{ event.start } - { event.end }</td>
                                                <td>
                                                    <div className="btn-group">
                                                        <Row>
                                                            <Col md={6}>
                                                                <i className="fas fa-edit text-primary"></i>
                                                            </Col>
                                                            <Col md={6}>
                                                                <i className="fas fa-trash text-danger"></i>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>

            <Add  isOpen={add} toggle={toggle} />
        </>
    )
}

export default withNamespaces()(Calendar);
