import React, {useEffect, useState} from "react";
import {Card, Col, Container, Form, FormGroup, Input, Label, Row, Table} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {formatDate} from "../../../utils/format";
import {axiosApi} from "../../../helpers/Api";
import {withNamespaces} from "react-i18next";
import Pagination from "../../../components/Common/Pagination";
import NoMatches from "../../../components/NoMatches";

const InviteLinks = (props) => {

    const [links, setLinks] = useState([]);

    /**
     * @desc Method to get all links.
     */
    const getLinks = () => {
        axiosApi.get(process.env.REACT_APP_IZEVEN_URL + 'api/invitations')
            .then(response => {
                setLinks(response.data.invitations);
            });
    }

    let breadcrumbItems = [
        {title: "Odin", link: "/"},
        {title: "Izeven", link: "/izeven"},
        {title: "Invite Links", link: "#"},
    ];

    useEffect(() => {
        getLinks();
    }, []);

    return (
        <>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="Invite Links" breadcrumbItems={breadcrumbItems}/>

                    <Card>
                        {
                            links.length ?
                                <div className="px-4 py-4">
                                    <Table responsive>
                                        <thead className="table-light">
                                        <tr>
                                            <th>{props.t('Name')}</th>
                                            <th>{props.t('Email')}</th>
                                            <th>{props.t('Phone')}</th>
                                            <th>{props.t('Code')}</th>
                                            <th>{props.t('Date')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            links.map((link, index) =>
                                                <tr key={index}>
                                                    <td>{link.name}</td>
                                                    <td>{link.email}</td>
                                                    <td>{link.phone}</td>
                                                    <td>{link.code}</td>
                                                    <td>{formatDate(link.created_at)}</td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                </div> : <NoMatches/>
                        }
                    </Card>

                </Container>
            </div>
        </>
    )
}

export default withNamespaces()(InviteLinks);
