import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {axiosApi} from "../../../../../../../helpers/Api";
import toastr from "toastr";

const BillingInfo = (props) => {

    const [country, setCountry] = useState(props.user.billing_info ? props.user.billing_info.country : '');
    const [state, setState] = useState(props.user.billing_info ? props.user.billing_info.state : '');
    const [city, setCity] = useState(props.user.billing_info ? props.user.billing_info.city : '');
    const [address, setAddress] = useState(props.user.billing_info ? props.user.billing_info.address : '');

    const update = () => {
        let data = {
            user_id: props.user.id,
            country: country,
            state: state,
            city: city,
            address: address
        }

        axiosApi.put(process.env.REACT_APP_IZEVEN_URL + 'api/users/update-billing-info', data)
            .then(response => {
                alert('User updated successfully');
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <Row>
            <Col md={12}>
                <h3 className="text-primary">Billing Info</h3>
                <hr/>
                <Form>
                    <FormGroup>
                        <Label>{props.t('Country')}</Label>
                        <Input value={country} onChange={(e) => setCountry(e.target.value)} id="country" type="text" />
                    </FormGroup>

                    <FormGroup>
                        <Label>{props.t('State')}</Label>
                        <Input value={state} onChange={(e) => setState(e.target.value)} id="state" type="text" />
                    </FormGroup>

                    <FormGroup>
                        <Label>{props.t('City')}</Label>
                        <Input value={city} onChange={(e) => setCity(e.target.value)} id="city" type="text" />
                    </FormGroup>

                    <FormGroup>
                        <Label>{props.t('Address')}</Label>
                        <Input value={address} onChange={(e) => setAddress(e.target.value)} id="address" type="text" />
                    </FormGroup>

                    <Button onClick={update} color="primary" className="w-md waves-effect waves-light">{props.t('Update')}</Button>
                </Form>
            </Col>
        </Row>
    )
}

export default withNamespaces()(BillingInfo);
