import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {Card, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import Data from "../../Izeven/User/Sections/Data";
import Wallet from "../../Izeven/User/Sections/Wallet";
import Lessons from "./Sections/Lessons";
import Categories from "./Sections/Categories";
import Tags from "./Sections/Tags";

const RecordedLessons = (props) => {

    const [activeTabJustify, setActiveTabJustify] = useState("lessons");
    const toggleCustomJustified = (id) => setActiveTabJustify(id);

    let breadcrumbItems = [
        {title: "Odin", link: "/"},
        {title: "Mindpro", link: "/mindpro"},
        {title: "Recorded Lessons", link: "#"}
    ];

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Recorded Lessons" breadcrumbItems={breadcrumbItems}/>

                    <Row>
                        <Col md={12}>
                            <Nav tabs className="nav-tabs-custom nav-justified">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTabJustify === "lessons"
                                        })}
                                        onClick={() => {
                                            toggleCustomJustified("lessons");
                                        }}
                                    >
                                        <span className="d-sm-block">Recorded Lessons</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTabJustify === "categories"
                                        })}
                                        onClick={() => {
                                            toggleCustomJustified("categories");
                                        }}
                                    >
                                        <span className="d-sm-block">Categories</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTabJustify === "tags"
                                        })}
                                        onClick={() => {
                                            toggleCustomJustified("tags");
                                        }}
                                    >
                                        <span className="d-sm-block">Tags</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>

                    <Card>
                        <Row>
                            <Col md={12}>
                                <TabContent activeTab={activeTabJustify}>
                                    <TabPane tabId="lessons" className="p-3">
                                        <Lessons />
                                    </TabPane>
                                    <TabPane tabId="categories" className="p-3">
                                        <Categories />
                                    </TabPane>
                                    <TabPane tabId="tags" className="p-3">
                                        <Tags />
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </Card>

                </Container>
            </div>
        </>
    )
}

export default withNamespaces()(RecordedLessons);
