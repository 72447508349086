import React, {useState} from "react";
import {Button, Card, CardBody, Col, FormGroup, Input, Label, Row, Table} from "reactstrap";
import {withNamespaces} from "react-i18next";

const Documents = (props) => {

    const [title, setTitle] = useState('');

    return (
        <Card>
            <CardBody>
                <h4 className="text-primary">Documents</h4>
                <p>In this section you can upload important documents of the project.</p>
                <hr/>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label>{props.t('Title')}</Label>
                            <Input type="text" value={title} onChange={(e) => setTitle(e.target.value)}/>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label>{props.t('Document')}</Label>
                            <div style={{ width: '100%' }}>
                                <Button style={{ width: '100%' }} outline block color="primary">Click to upload a file</Button>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label className="text-white">{props.t('Document')}</Label>
                            <div style={{ width: '100%' }}>
                                <Button style={{ width: '100%' }} block color="primary">Add Document</Button>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <hr/>
                <Table responsive>
                    <thead className="table-light">
                    <tr>
                        <th>{props.t('Title')}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    )
}

export default withNamespaces()(Documents);
