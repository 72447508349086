import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {Button, Card, CardBody, Container, Form, FormGroup, FormText, Input, Label, Row} from "reactstrap";

const Radio = (props) => {

    const [title, setTitle] = useState(null);
    const [speaker, setSpeaker] = useState(null);
    const [description, setDescription] = useState(null);
    const [eventImage, setEventImage] = useState(null);
    const [cover, setCover] = useState(null);

    let breadcrumbItems = [
        {title: "Odin", link: "/"},
        {title: "Avanx", link: "/avanx"},
        {title: "Radio", link: "/avanx/radio"},
    ];

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Radio" breadcrumbItems={breadcrumbItems}/>

                    <Row>
                        <Card>
                            <CardBody>
                                <FormGroup>
                                    <Label>{props.t('Title')}</Label>
                                    <Input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{props.t('Speaker')}</Label>
                                    <Input type="text" value={speaker} onChange={(e) => setSpeaker(e.target.value)} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{props.t('Description')}</Label>
                                    <Input type="textarea" value={description} onChange={(e) => setDescription(e.target.value)} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{props.t('Cover')}</Label>
                                    <Row className="mx-1">
                                        <Button color="primary" block outline>
                                            <i className="fa fa-upload"/> Upload Event Cover
                                        </Button>
                                    </Row>
                                </FormGroup>
                                <hr/>
                                <Row className="mx-1">
                                    <Button color="primary" block>
                                        <i className="fas fa-radio"/> Start Transmission
                                    </Button>
                                </Row>
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default withNamespaces()(Radio);
