import moment from "moment";
import currency from "currency.js";

/**
 * @desc Method to format dates.
 * @param date
 * @returns {string}
 */
export const formatDate = (date) => {
    return moment(date).format('DD-MM-YYYY')
}

/**
 * @desc Method to format currency.
 * @param value
 * @returns {string}
 */
export const formatCurrency = (value) => {
    return currency(value).format()
}
