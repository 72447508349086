import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const Add = (props) => {

    const [title, setTitle] = useState(null);
    const [speaker, setSpeaker] = useState(null);
    const [url, setUrl] = useState(null);

    return (
        <Modal centered toggle={props.toggle} isOpen={props.isOpen}>
            <ModalHeader charCode="Y" toggle={props.toggle}/>
            <ModalBody>
                <h3 className="mb-3 text-primary">{props.t('Add Video')}</h3>
                <hr/>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Title')}</Label>
                    <Input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Speaker')}</Label>
                    <Input type="text" value={speaker} onChange={(e) => setSpeaker(e.target.value)} />
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Url')}</Label>
                    <Input type="text" value={url} onChange={(e) => setUrl(e.target.value)} />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary">{props.t('Add')}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default withNamespaces()(Add);
