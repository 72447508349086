import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Link} from "react-router-dom";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ISelect from "../../../../../components/Select";
import {axiosApi} from "../../../../../helpers/Api";
import toastr from "toastr";

const Expiration = (props) => {

    const [expirationDate, setExpirationDate] = useState(null);
    const [pointsUp, setPointsUp] = useState(null);

    const upgrade = () => {

        let data = {
            email: props.user.email,
            date: expirationDate,
            withPoints: pointsUp === 'true',
        }

        axiosApi.post(process.env.REACT_APP_IZEVEN_URL + 'api/users/change_expiracy', data)
            .then(response => {
                toastr.success(props.t('Expiration date was updated with success.'), 'Success');
                props.getUser();
                props.toggle();
            })
            .catch(error => {
                console.log(error);
            })
    }

    // Enable button.
    const enabled = () => {
        return !(expirationDate && pointsUp)
    }

    return (
        <Modal centered toggle={props.toggle} isOpen={props.isOpen}>
            <ModalHeader charCode="Y" toggle={props.toggle}/>
            <ModalBody>
                <h3 className="mb-3 text-primary">{props.t('Change expiration date')}</h3>
                <Form>
                    <FormGroup floating className="mb-1">
                        <Label>{props.t('Expiration Date')}</Label>
                        <Input value={expirationDate} onChange={(e) => setExpirationDate(e.target.value)} id="expiration_date" type="date" />
                    </FormGroup>
                </Form>
                <hr/>
                <p>{props.t('Points Up?')}</p>
                <Form onChange={(e) => setPointsUp(e.target.value) }>
                    <FormGroup
                        check
                        inline
                    >
                        <Input value={true} name="link-side"  type="radio" />
                        <Label radioGroup className="mb-0">
                            {props.t('Yes')}
                        </Label>
                    </FormGroup>
                    <FormGroup
                        check
                        inline
                    >
                        <Input value={false} name="link-side"  type="radio" />
                        <Label radioGroup className="mb-0">
                            {props.t('No')}
                        </Label>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button disabled={enabled()} onClick={upgrade} color="primary">{props.t('Update')}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default withNamespaces()(Expiration);
