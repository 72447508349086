import React, {useEffect, useState} from 'react';
import {withNamespaces} from "react-i18next";
import {Button, Col, FormGroup, Label, Row, Table} from "reactstrap";
import Create from "./Components/Create";
import NoMatches from "../../../../../components/NoMatches";
import {axiosApi} from "../../../../../helpers/Api";
import {formatDate} from "../../../../../utils/format";
import Update from "./Components/Update";

const PrivateMentorings = (props) => {

    // Array of mentorings objects.
    const [mentorings, setMentorings] = useState([]);

    // State to manage the creation modal.
    const [create, setCreate] = useState(false);
    const toggleCreate = () => setCreate(!create);

    // State to manage the update modal.
    const [mentoring, setMentoring] = useState(null);
    const [update, setUpdate] = useState(false);
    const toggleUpdate = (mentoring) => {
        setMentoring(mentoring)
        setUpdate(!update)
    };

    /**
     * @desc Method to get mentorings.
     * @returns {Promise<void>}
     */
    const getMentorings = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/private-mentorings/course/' + props.course.id)
            .then((response) => setMentorings(response.data))
            .catch((error) => console.error(error));
    }

    /**
     * @desc Method to delete a mentoring.
     * @param id
     * @returns {Promise<void>}
     */
    const deleteMentoring = async (id) => {
        await axiosApi.delete(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/private-mentorings/' + id)
            .then((response) => getMentorings())
            .catch((error) => console.error(error));
    }

    useEffect(() => {
        getMentorings();
    }, []);

    return (
        <>
            <Row className="justify-content-end">
                <Col md={4}>
                    <FormGroup>
                        <Label style={{color: 'white'}}>Add New</Label>
                        <Button onClick={toggleCreate} style={{width: "100%"}} color="primary"> <i
                            className="fa fa-plus"/> Add New</Button>
                    </FormGroup>
                </Col>
            </Row>

            {
                mentorings.length ?
                    <>
                        <div className="px-4">
                            <Table responsive>
                                <thead className="table-light">
                                <tr>
                                    <th>#</th>
                                    <th>{props.t('Title')}</th>
                                    <th>{props.t('Date')}</th>
                                    <th>{props.t('Link')}</th>
                                    <th>{props.t('Educator')}</th>
                                    <th>{props.t('Actions')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    mentorings.map((mentoring, index) => (
                                        <tr>
                                            <td>{mentoring.id}</td>
                                            <td>{mentoring.title}</td>
                                            <td>{formatDate(mentoring.date)}</td>
                                            <td>{mentoring.link}</td>
                                            <td>{mentoring.educator.name}</td>
                                            <td>
                                                <div className="btn-group">
                                                    <Row>
                                                        <Col md={6}>
                                                            <i onClick={() => toggleUpdate(mentoring)}
                                                               className="fas fa-edit text-primary"
                                                               style={{cursor: "pointer"}}></i>
                                                        </Col>
                                                        <Col md={6}>
                                                            <i onClick={() => deleteMentoring(mentoring.id)}
                                                               className="fas fa-trash text-danger"
                                                               style={{cursor: "pointer"}}></i>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </div>
                    </> : <NoMatches/>
            }

            <Create toggle={toggleCreate} isOpen={create} course={props.course} getMentorings={getMentorings}/>

            {
                mentoring ?
                    <Update toggle={toggleUpdate} isOpen={update} mentoring={mentoring}
                            getMentorings={getMentorings}/> : <></>
            }
        </>
    )
}

export default withNamespaces()(PrivateMentorings);
