import React, {useEffect, useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Card, Col, Container, Row, Table} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {Link} from "react-router-dom";
import Create from "./Components/Create";
import {axiosApi} from "../../../helpers/Api";
import {formatDate} from "../../../utils/format";

const Courses = (props) => {

    const [courses, setCourses] = useState([]);

    const [create, setCreate] = useState(false);
    const toggle = () => setCreate(!create);

    let breadcrumbItems = [
        {title: "Odin", link: "/"},
        {title: "Mindpro", link: "/mindpro"},
        {title: "Courses", link: "#"}
    ];

    const getCourses = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/courses')
            .then(response => {
                setCourses(response.data);
            })
            .catch(error => console.log(error));
    }

    useEffect(() => {
        getCourses();
    }, []);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Mindpro" breadcrumbItems={breadcrumbItems}/>

                    <Card>
                        <div className="px-4 py-4">

                            <Row className="justify-content-end mb-4">
                                <Col md={2} className="text-end">
                                    <Button onClick={ toggle } className="btn-rounded" block color="primary"> <i className="fas fa-plus"/> Add New</Button>
                                </Col>
                            </Row>

                            <Table responsive>
                                <thead className="table-light">
                                <tr>
                                    <th>{props.t('Name')}</th>
                                    <th>{props.t('Educators')}</th>
                                    <th>{props.t('Created at')}</th>
                                    <th>{props.t('Actions')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    courses.map(course => (
                                        <tr>
                                            <td><Link to={ '/mindpro/course/' + course.id }>{ course.title }</Link></td>
                                            <td>
                                                { course.educators.map((educator, index) => educator.name + (index === course.educators.length -1 ? '' : ', ')  ) }
                                            </td>
                                            <td>
                                                { formatDate(course.created_at) }
                                            </td>
                                            <td>
                                                <div className="btn-group">
                                                    <Row>
                                                        <Col md={6}>
                                                            <i className="fas fa-edit text-primary"></i>
                                                        </Col>
                                                        <Col md={6}>
                                                            <i className="fas fa-trash text-danger"></i>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </div>
                    </Card>
                </Container>
            </div>

            <Create toggle={toggle} isOpen={create}  getCourses={getCourses} />
        </>
    )
}

export default withNamespaces()(Courses);
