import React, {Component, useEffect} from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from 'react-i18next';

import {useSelector} from "react-redux";

const SidebarContent = (props) => {

    const {user} = useSelector((state) => state.user);

    useEffect(() => {
        initMenu();
    }, []);

    const initMenu = () => {
        new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }

    const activateParentDropdown = (item) => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    return (
        <>
            <div id="sidebar-menu">

                <ul className="metismenu list-unstyled" id="side-menu">
                    <li className="menu-title">{props.t('Menu')}</li>

                    <li>
                        <Link to="/dashboard" className="waves-effect">
                            <i className="fas fa-brain"></i>
                            <span className="ms-1">{props.t('Dashboard')}</span>
                        </Link>
                    </li>

                    {
                        user.type.name === 'Superadmin' || user.type.name === 'Admin' ?
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="fas fa-project-diagram"></i>
                                    <span className="ms-1">{props.t('Izeven')}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li><Link to="/izeven/invite-links">{props.t('Invite Links')}</Link></li>
                                    <li><Link to="/izeven/pending-users">{props.t('Pending Users')}</Link></li>
                                    <li><Link to="/izeven/withdrawals">{props.t('Withdrawals')}</Link></li>
                                    <li><Link to="/izeven/users">{props.t('Users')}</Link></li>
                                    <li><Link to="/izeven/network">{props.t('Network')}</Link></li>
                                    <li><Link to="/izeven/kyc">{props.t('Kyc')}</Link></li>
                                    <li><Link to="/izeven/reports">{props.t('Reports')}</Link></li>
                                    {
                                        user.type.name === 'Superadmin' ?
                                            <>
                                                <li><Link to="/">{props.t('Accounting')}</Link></li>
                                                <li><Link to="/izeven">{props.t('Messages')}</Link></li>
                                            </> : <></>
                                    }
                                </ul>
                            </li> : <></>
                    }

                    {
                        user.type.name === 'Superadmin' || user.type.name === 'Educator' ?
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="fas fa-graduation-cap"></i>
                                    <span className="ms-1">{props.t('Master')}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li><Link to="/signals">{props.t('Signals')}</Link></li>
                                    <li><Link to="/mindpro/courses">{props.t('Courses')}</Link></li>
                                    <li><Link to="/mindpro/masterclass">{props.t('Masterclass')}</Link></li>
                                    <li><Link to="/mindpro/recorded-lessons">{props.t('Recorded Lessons')}</Link></li>
                                    {
                                        user.type.name === 'Superadmin' ?
                                            <li><Link to="/mindpro/educators">{props.t('Educators')}</Link></li> : <></>
                                    }
                                </ul>
                            </li> : <></>
                    }

                    <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="fas fa-rocket"></i>
                            <span className="ms-1">{props.t('Signals')}</span>
                        </Link>
                        <ul className="sub-menu">
                            <li><Link to="/signals/educators">{props.t('Educators')}</Link></li>
                            <li><Link to="/signals/signals">{props.t('Signals')}</Link></li>
                            <li><Link to="/signals/markets">{props.t('Markets')}</Link></li>                            
                        </ul>
                    </li> : <></>

                    {
                        user.type.name === 'Superadmin' ?
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="fas fa-rocket"></i>
                                    <span className="ms-1">{props.t('Avanx')}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li><Link to="/">{props.t('TV')}</Link></li>
                                    <li><Link to="/avanx/radio">{props.t('Radio')}</Link></li>
                                    <li><Link to="/avanx/academy">{props.t('Academy')}</Link></li>
                                    <li><Link to="/avanx/calendar">{props.t('Calendar')}</Link></li>
                                </ul>
                            </li> : <></>
                    }

                    {
                        user.type.name === 'Superadmin' || user.type.name === 'Admin' ?
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="fas fa-building"></i>
                                    <span className="ms-1">{props.t('Realestate')}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li><Link to="/terra/projects">{props.t('Projects')}</Link></li>
                                </ul>
                            </li> : <></>
                    }

                    {
                        user.type.name === 'Superadmin' || user.type.name === 'Admin' ?
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="fas fa-leaf"></i>
                                    <span className="ms-1">{props.t('Agronomy')}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li><Link to="/agronomy/projects">{props.t('Projects')}</Link></li>
                                </ul>
                            </li> : <></>
                    }

                    {
                        user.type.name === 'Superadmin' ?
                            <li>
                                <Link to="/iup" className="waves-effect">
                                    <i className="fas fa-chart-line"></i>
                                    <span className="ms-1">{props.t('iUP')}</span>
                                </Link>
                            </li> : <> </>
                    }

                    {
                        user.type.name === 'Superadmin' ?
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="fas fa-shopping-bag"></i>
                                    <span className="ms-1">{props.t('Tivigow')}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li><Link to="/">{props.t('Orders')}</Link></li>
                                    <li><Link to="/">{props.t('Suppliers')}</Link></li>
                                    <li><Link to="/">{props.t('Withdrawals')}</Link></li>
                                </ul>
                            </li> : <></>
                    }
                </ul>
            </div>
        </>
    )
}

export default withRouter(withNamespaces()(SidebarContent));
