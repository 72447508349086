import React, {useEffect, useMemo, useRef, useState} from "react";
import {withNamespaces} from "react-i18next";
import {Card, CardBody, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import JoditEditor from "jodit-react";

const General = (props) => {

    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [map, setMap] = useState('');

    const editorDescription = useRef(null);
    const [description, setDescription] = useState('');

    const editorTerms = useRef(null)
    const [terms, setTerms] = useState('');

    useEffect(() => {
        if (description) {
            console.log(draftToHtml(description));
        }
    }, [description]);

    return (
        <Card>
            <CardBody>
                <h4 className="text-primary">General</h4>
                <p>In this section set the general info about the new project.</p>
                <hr/>
                <Form>
                    <FormGroup>
                        <Label>{props.t('Name')}</Label>
                        <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label>{props.t('Type')}</Label>
                        <Input type="select" value={type} onChange={(e) => setType(e.target.value)}>
                            <option value="liquidation">Liquidation</option>
                            <option value="retirement">Retirement</option>
                        </Input>
                    </FormGroup>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label>{props.t('Country')}</Label>
                                <Input type="text" value={country} onChange={(e) => setCountry(e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>{props.t('State')}</Label>
                                <Input type="text" value={state} onChange={(e) => setState(e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>{props.t('City')}</Label>
                                <Input type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label>{props.t('Map Link')}</Label>
                        <Input type="text" value={map} onChange={(e) => setMap(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label>{props.t('Description')}</Label>
                        <JoditEditor
                            ref={editorDescription}
                            config={{
                                placeholder: 'Write the description of the project'
                            }}
                            value={description}
                            tabIndex={1}
                            onBlur={newContent => setTerms(newContent)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{props.t('Terms')}</Label>
                        <JoditEditor
                            ref={editorTerms}
                            config={{
                                placeholder: 'Write the terms & conditions of the project'
                            }}
                            value={terms}
                            tabIndex={1}
                            onBlur={newContent => setTerms(newContent)}
                        />
                    </FormGroup>
                </Form>
            </CardBody>
        </Card>
    )
}

export default withNamespaces()(General);
