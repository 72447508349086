import axios from 'axios'

const TOKEN = localStorage.getItem('auth_token')
const API_URL = process.env.REACT_APP_ODIN_URL

export const axiosApi = axios.create({
    baseURL: API_URL
})

axiosApi.defaults.headers.common["Authorization"] = TOKEN

axiosApi.interceptors.response.use(
    response => response,
    error => Promise.reject(error)
)

export async function get(url, config = {}) {
    return await axiosApi.get(url, { ...config })
        .then(response => response.data)
        .catch(error => "GET FAILED")
}

export async function post(url, data, config = {}) {
    return axiosApi.post(url, { ...data }, { ...config })
        .then(response => {
            return response.data
        })
        .catch(error => "POST FAILED")
}

export async function put(url, data, config = {}) {
    return axiosApi
        .put(url, { ...data }, { ...config })
        .then(response => response.data)
}

export async function del(url, config = {}) {
    return await axiosApi
        .delete(url, { ...config })
        .then(response => response.data)
}
