import React from "react";
import {withNamespaces} from "react-i18next";
import {Col, Row} from "reactstrap";
import empty from "../../assets/images/empty-box.png";

import './styles/index.css';

const NoMatches = ({size = 200}) => {
    return (
        <Row className="justify-content-center">
            <Col className="text-center mt-5 mb-5" md={4}>
                <img className="mt-4 mb-4" width={size} src={empty} alt="empty"/>
                <h1 className="title">NO MATCHES</h1>
            </Col>
        </Row>
    )
}

export default withNamespaces()(NoMatches);
