import React, {useEffect, useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, FormGroup, Input, Label, Row, Table} from "reactstrap";
import Select from "react-select";
import {axiosApi} from "../../../../helpers/Api";

const Details = (props) => {

    const {course} = props;

    const [educatorsList, setEducatorsList] = useState([]);

    const [title, setTitle] = useState('');
    const [educators, setEducators] = useState([]);
    const [educatorsToDelete, setEducatorsToDelete] = useState([]);
    const [newEducators, setNewEducators] = useState([]);
    const [time, setTime] = useState('');
    const [description, setDescription] = useState('');

    const getEducators = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/educators')
            .then(response => {
                setEducatorsList(response.data.map(element => ({label: element.name, value: element.id})));
            })
            .catch(error => console.error(error));
    }

    const save = async () => {

        console.log(newEducators);

        let data = {
            title: title,
            educators: newEducators.map(educator => educator.value),
            educators_to_delete: educatorsToDelete,
            duration: time,
            description: description
        }

        await axiosApi.put(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/course/' + course.id, data)
            .then(response => {
                props.getCourse();
            })
            .catch(error => console.error(error));
    }

    const removeFromCurrent = (index) => {
        let tmp = [...educators];
        let position = tmp.findIndex((e) => e.id === index);
        tmp.splice(position, 1);
        setEducators(tmp);
    }

    const addToDelete = (index) => {
        removeFromCurrent(index);

        let tmp = [...educatorsToDelete];
        tmp.push(index);
        setEducatorsToDelete(tmp);
    }

    const setters = () => {
        setTitle(course.title);
        setTime(course.duration);
        setDescription(course.description);
        setEducators(course.educators);
    }

    useEffect(() => {
        setters();
    }, [props]);

    useEffect(() => {
        getEducators();
    }, []);

    return (
        <>
            <h3 className="mb-3 text-primary">{props.t('Details')}</h3>
            <hr/>
            <FormGroup floating className="mb-1">
                <Label>{props.t('Title')}</Label>
                <Input value={title} onChange={(e) => setTitle(e.target.value)} type="text"/>
            </FormGroup>
            <FormGroup floating className="mb-1">
                <Label>{props.t('Educators')}</Label>
                <Select onChange={(e) => setNewEducators(e)} options={educatorsList} isMulti placeholder="Select the educators of course..."/>
            </FormGroup>
            <Table>
                <thead>
                    <tr>
                        <th>Educator</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {
                    educators.map((educator) =>
                        <tr>
                            <td>{ educator.name }</td>
                            <td><i onClick={() => addToDelete(educator.id)} className="fa fa-trash text-danger" style={{ cursor: "pointer" }}/></td>
                        </tr>
                    )
                }
                </tbody>
            </Table>
            <FormGroup floating className="mb-1">
                <Label>{props.t('Time')}</Label>
                <Input value={time} onChange={(e) => setTime(e.target.value)} type="text"/>
            </FormGroup>
            <FormGroup floating className="mb-1">
                <Label>{props.t('Description')}</Label>
                <Input value={description} onChange={(e) => setDescription(e.target.value)} type="textarea"/>
            </FormGroup>
            <hr/>
            <Row className="mx-1 text-right">
                <Button onClick={save} color="primary">
                    Save
                </Button>
            </Row>
        </>
    )
}

export default withNamespaces()(Details);
