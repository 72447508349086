import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Col, FormGroup, FormText, Input, Label, Row} from "reactstrap";
import {axiosApi} from "../../../../../../../helpers/Api";
import toastr from "toastr";

const Password = (props) => {

    const [password, setPassword] = useState('');

    const update = () => {
        let data = {
            user_id: props.user.id,
            password: password
        }

        axiosApi.post(process.env.REACT_APP_IZEVEN_URL + 'api/change-password', data)
            .then(response => {
                toastr.success(props.t('Password changed with success.'), 'Success');
                setPassword('');
                props.getUser();
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <Row>
            <Col md={12}>
                <h3 className="text-primary">Temporal Password</h3>
                <hr/>
                <FormGroup>
                    <Label>{props.t('Current User Password')}</Label>
                    <h5>{ props.user.password }</h5>
                </FormGroup>
                <FormGroup>
                    <Label>{props.t('Password')}</Label>
                    <Input value={password} onChange={(e) => setPassword(e.target.value)} id="name" type="text" />
                    <FormText>To set 'IZEVEN2022' for the pass use this encrypt <span className="text-primary">$2y$10$qnrWMOV2CmPVuoK3kfx8bOVTqNpeMNcxVhs8p1Ra4fnsU5fLZPxHy</span></FormText>
                </FormGroup>
                <Button disabled={!password.length} onClick={update} color="primary" className="w-md waves-effect waves-light">{props.t('Change Password')}</Button>
            </Col>
        </Row>
    )
}

export default withNamespaces()(Password);
