import React, {useEffect, useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {axiosApi} from "../../../../../../helpers/Api";
import Select from "react-select";

const Create = (props) => {

    const [educatorsList, setEducatorsList] = useState([]);
    const [plansList, setPlansList] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);
    const [tagsList, setTagsList] = useState([]);

    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [date, setDate] = useState('');
    const [educator, setEducator] = useState('');
    const [plan, setPlan] = useState('');
    const [category, setCategory] = useState('');
    const [tags, setTags] = useState('');

    const save = async () => {
        let data = {
            title: title,
            link: link,
            date: date,
            educators: educator,
            plan_id: plan,
            category_id: category,
            tags: tags.toString(),
        }

        await axiosApi.post(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/recorded-lessons/create', data)
            .then(response => {
                props.getLessons();
                props.toggle();
            })
            .catch(error => console.error(error));
    }

    const validate = () => {
        return !(title && link && date && educator && plan && category && tags);
    }

    const getCategories = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/recorded-lessons/categories')
            .then(response => {
                setCategoriesList(response.data.map(element => ({ label: element.name, value: element.id })));
            })
            .catch(error => console.error(error));
    }

    const getTags = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/recorded-lessons/tags')
            .then(response => {
                setTagsList(response.data.map(element => ({ label: element.name, value: element.name })));
            })
            .catch(error => console.error(error));
    }

    const getPlans = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/plans')
            .then(response => {
                setPlansList(response.data.map(element => ({ label: element.name, value: element.id })));
            })
            .catch(error => console.error(error));
    }

    const getEducators = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/educators')
            .then(response => {
                setEducatorsList(response.data.map(element => ({ label: element.name, value: element.id })));
            })
            .catch(error => console.error(error));
    }


    useEffect(() => {
        getEducators();
        getPlans();
        getCategories();
        getTags();
    }, []);


    return (
        <Modal centered toggle={props.toggle} isOpen={props.isOpen}>
            <ModalHeader charCode="Y" toggle={props.toggle} />
            <ModalBody>
                <h3 className="mb-3 text-primary">{ props.t('Add Lesson') }</h3>
                <hr/>
                <FormGroup floating className="mb-1">
                    <Label>{ props.t('Title') }</Label>
                    <Input type="text" value={title} onChange={(e) => setTitle(e.target.value)}/>
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{ props.t('Lesson Link') }</Label>
                    <Input type="url" value={link} onChange={(e) => setLink(e.target.value)}/>
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{ props.t('Date') }</Label>
                    <Input type="date" value={date} onChange={(e) => setDate(e.target.value)}/>
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{ props.t('Educators') }</Label>
                    <Select options={educatorsList} isMulti onChange={(e) => setEducator(e.map(e => e.value))} placeholder="Select the educators of lesson..." />
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{ props.t('Min Plan') }</Label>
                    <Select options={plansList} onChange={(e) => setPlan(e.value)} placeholder="Select the min plan for the lesson..." />
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{ props.t('Category') }</Label>
                    <Select options={categoriesList} onChange={(e) => setCategory(e.value)} placeholder="Select the category of the lesson..." />
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{ props.t('Tags') }</Label>
                    <Select options={tagsList} isMulti onChange={(e) => setTags(e.map(e => e.value))} placeholder="Select the tags of the lesson..." />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button onClick={save} disabled={validate()} color="primary">{props.t('Add')}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default withNamespaces()(Create);
