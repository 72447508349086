import React, {Component, useEffect, useState} from "react";
import {Container, Row, Col, Card, CardBody} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {axiosApi} from "../../helpers/Api";
import {useSelector} from "react-redux";

const Dashboard = () => {

    const {user} = useSelector((state) => state.user);

    const [totalUsers, setTotalUsers] = useState(0);
    const [activeUsers, setActiveUsers] = useState(0);
    const [newUsers, setNewUsers] = useState(0);
    const [usersBySubscription, setUsersBySubscription] = useState([]);
    const [newUsersInMonthBySubscription, setNewUsersInMonthBySubscription] = useState([]);
    const [walletTotal, setWalletTotal] = useState(0);

    const getStats = async () => {
        await axiosApi.get(process.env.REACT_APP_IZEVEN_URL + 'api/stats/odin')
            .then(response => {
                setTotalUsers(response.data.allUsers);
                setActiveUsers(response.data.activeUsers);
            });

        await axiosApi.get(process.env.REACT_APP_NETWORK_SERVICE_URL + 'api/odin')
            .then(response => {
                setNewUsers(response.data.newUsersInMonth);
                setUsersBySubscription(response.data.usersForSubscription);
                setNewUsersInMonthBySubscription(response.data.newUsersForSubscriptionInMonth)
            });

        await axiosApi.get(process.env.REACT_APP_WALLET_SERVICE_URL + 'api/odin')
            .then(response => {
                setWalletTotal(response.data.total);
            });
    }

    useEffect(() => {
        getStats();
    }, []);

    let breadcrumbItems = [
        { title : "Odin", link : "/" },
        { title : "Dashboard", link : "#" },
    ];

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />
                    {
                        user.type?.name !== 'Educator' ?
                        <>
                            <Row>
                                <Col md={3}>
                                    <Card>
                                        <CardBody className="text-center">
                                            <h1 className="fw-bold text-secondary" style={{ fontSize: '50px' }}>{ totalUsers }</h1>
                                            <h5 className="text-primary">TOTAL USERS</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={3}>
                                    <Card>
                                        <CardBody className="text-center">
                                            <h1 className="fw-bold text-secondary" style={{ fontSize: '50px' }}>{ activeUsers }</h1>
                                            <h5 className="text-primary">ACTIVE USERS</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={3}>
                                    <Card>
                                        <CardBody className="text-center">
                                            <h1 className="fw-bold text-secondary" style={{ fontSize: '50px' }}>{ newUsers }</h1>
                                            <h5 className="text-primary">NEW USERS</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={3}>
                                    <Card>
                                        <CardBody className="text-center">
                                            <h2 className="fw-bold text-secondary" style={{ fontSize: '50px' }}>${ walletTotal }</h2>
                                            <h5 className="text-primary">Total In Wallets</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Card>
                                    <CardBody>
                                        <h2 className="text-primary">Users By Subscription <small className="fw-bold">(This Month)</small></h2>
                                        <hr/>
                                        <Row>
                                            {
                                                newUsersInMonthBySubscription.map(plan =>
                                                    <Col className="text-center">
                                                        <h1 className="fw-bold text-secondary">{ plan.count }</h1>
                                                        <h4 className="text-primary">{ plan.subscription }</h4>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Row>
                            <Row>
                                <Card>
                                    <CardBody>
                                        <h2 className="text-primary">Users By Subscription</h2>
                                        <hr/>
                                        <Row>
                                            {
                                                usersBySubscription.map(plan =>
                                                    <Col className="text-center">
                                                        <h1 className="fw-bold text-secondary">{ plan.count }</h1>
                                                        <h4 className="text-primary">{ plan.subscription }</h4>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Row>
                        </> : null
                    }
                </Container>
            </div>
        </>
    )
}

export default Dashboard;
