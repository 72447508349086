import React, {useEffect, useState} from "react";
import {withNamespaces} from "react-i18next";
import {Link} from "react-router-dom";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ISelect from "../../../../../components/Select";
import {axiosApi} from "../../../../../helpers/Api";
import toastr from "toastr";

const Subscription = (props) => {

    const [subscription, setSubscription] = useState(null);
    const [payCommission, setPayCommission] = useState(null);
    const [pointsUp, setPointsUp] = useState(null);

    const [plans, setPlans] = useState([]);
    const getPlans = () => {
        axiosApi.get(process.env.REACT_APP_IZEVEN_URL + 'api/plans/superiors/' + props.plan)
            .then(response => {
                setPlans(response.data.map(element => ({label: element.name, value: element.id})));
            })
            .catch(error => {
                console.error(error);
            })
    }

    const upgrade = () => {
        let data = {
            email: props.user.email,
            plan_id: subscription,
            withPoints: pointsUp === 'true',
            withCommission: payCommission === 'true'
        }

        axiosApi.post(process.env.REACT_APP_IZEVEN_URL + 'api/users/upgrade', data)
            .then(response => {
                toastr.success(props.t('Upgrade made with success.'), 'Success');
                props.getUser();
                props.toggle();
            })
            .catch(error => {
                console.log(error);
            })
    }

    // Enable button.
    const enabled = () => {
        return !(subscription && pointsUp && payCommission)
    }

    return (
        <Modal centered onOpened={getPlans} toggle={props.toggle} isOpen={props.isOpen}>
            <ModalHeader charCode="Y" toggle={props.toggle}/>
            <ModalBody>
                <h3 className="mb-3 text-primary">{props.t('Upgrade user subscription')}</h3>
                <Form>
                    <FormGroup floating className="mb-1">
                        <Label>{props.t('Subscription')}</Label>
                        <ISelect value={subscription} onChange={(e) => setSubscription(e.value)} options={plans}
                                 placeholder="Select plan..."/>
                    </FormGroup>
                </Form>
                <hr/>
                <p>{props.t('Pay commission?')}</p>
                <Form onChange={(e) => setPayCommission(e.target.value)}>
                    <FormGroup
                        check
                        inline
                    >
                        <Input value={true} name="link-side" type="radio"/>
                        <Label radioGroup className="mb-0">
                            {props.t('Yes')}
                        </Label>
                    </FormGroup>
                    <FormGroup
                        check
                        inline
                    >
                        <Input value={false} name="link-side" type="radio"/>
                        <Label radioGroup className="mb-0">
                            {props.t('No')}
                        </Label>
                    </FormGroup>
                </Form>
                <hr/>
                <p>{props.t('Points up?')}</p>
                <Form onChange={(e) => setPointsUp(e.target.value)}>
                    <FormGroup
                        check
                        inline
                    >
                        <Input value={true} name="link-side" type="radio"/>
                        <Label radioGroup className="mb-0">
                            {props.t('Yes')}
                        </Label>
                    </FormGroup>
                    <FormGroup
                        check
                        inline
                    >
                        <Input value={false} name="link-side" type="radio"/>
                        <Label radioGroup className="mb-0">
                            {props.t('No')}
                        </Label>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button onClick={ upgrade } color="primary">{props.t('Make Upgrade')}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default withNamespaces()(Subscription);
