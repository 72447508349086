import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";

const Multimedia = (props) => {

    const [tabs, setTabs] = useState('images');
    const toggleTabs = (id) => setTabs(id);

    return (
        <Card>
            <CardBody>
                <h4 className="text-primary">Multimedia</h4>
                <p>In this section you can link multimedia like a images or videos of the project.</p>
                <hr/>
                <Nav pills className="navtab-bg nav-justified">
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: tabs === "images"
                            })}
                            onClick={() => {
                                toggleTabs("images");
                            }}
                        >
                            Images
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: tabs === "videos"
                            })}
                            onClick={() => {
                                toggleTabs("videos");
                            }}
                        >
                            Videos
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={tabs}>
                    <TabPane tabId="images" className="p-3">
                        Images
                    </TabPane>
                    <TabPane tabId="videos" className="p-3">
                        Videos
                    </TabPane>
                </TabContent>
            </CardBody>
        </Card>
    )
}

export default withNamespaces()(Multimedia);
