import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {Col, Container, Nav, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import General from "./Sections/General";
import Documents from "./Sections/Documents";
import Multimedia from "./Sections/Multimedia";
import Admon from "./Sections/Admon";

const AddProject = (props) => {

    const [activeTab, setActiveTab] = useState('general');
    const toggleActiveTab = (id) => setActiveTab(id);

    let breadcrumbItems = [
        {title: "Odin", link: "/"},
        {title: "Terra", link: "/terra"},
        {title: "Projects", link: "/terra/projects"},
        {title: "Add", link: "#"},
    ];

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Add Project" breadcrumbItems={breadcrumbItems}/>

                    <Row>
                        <Col sm={2}>
                            <Nav pills className="flex-column" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <NavLink id="v-pills-home-tab" style={{cursor: "pointer"}}
                                         className={classnames({
                                             active: activeTab === "general"
                                         }, "mb-2")}
                                         onClick={() => {
                                             toggleActiveTab("general");
                                         }} aria-controls="v-pills-home" aria-selected="true">
                                    General
                                </NavLink>
                                <NavLink id="v-pills-profile-tab" style={{cursor: "pointer"}}
                                         className={classnames({
                                             active: activeTab === "documents"
                                         }, "mb-2")}
                                         onClick={() => {
                                             toggleActiveTab("documents");
                                         }} aria-controls="v-pills-home" aria-selected="true">
                                    Documents
                                </NavLink>
                                <NavLink id="v-pills-messages-tab" style={{cursor: "pointer"}}
                                         className={classnames({
                                             active: activeTab === "multimedia"
                                         }, "mb-2")}
                                         onClick={() => {
                                             toggleActiveTab("multimedia");
                                         }} aria-controls="v-pills-home" aria-selected="true">
                                    Multimedia
                                </NavLink>
                                <NavLink id="v-pills-settings-tab" style={{cursor: "pointer"}}
                                         className={classnames({
                                             active: activeTab === "admon"
                                         })}
                                         onClick={() => {
                                             toggleActiveTab("admon");
                                         }} aria-controls="v-pills-home" aria-selected="true">
                                    Admon
                                </NavLink>
                            </Nav>
                        </Col>
                        <Col sm={10}>
                            <TabContent activeTab={activeTab} className="text-muted" id="v-pills-tabContent">
                                <TabPane tabId="general" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                    <General />
                                </TabPane>
                                <TabPane tabId="documents" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                    <Documents />
                                </TabPane>
                                <TabPane tabId="multimedia" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                    <Multimedia />
                                </TabPane>
                                <TabPane tabId="admon" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                    <Admon />
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default withNamespaces()(AddProject);
