import React, {useEffect, useState} from "react";
import {Button, Card, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Table} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {formatDate} from "../../../utils/format";
import {axiosApi} from "../../../helpers/Api";
import {withNamespaces} from "react-i18next";
import Pagination from "../../../components/Common/Pagination";
import ActiveSubscription from "./Components/ActiveSubscription";
import Swal from "sweetalert2";
import toastr from "toastr";
import NoMatches from "../../../components/NoMatches";

const PendingUsers = (props) => {

    const [users, setUsers] = useState([]);
    const [pages, setPages] = useState([]);

    const [user, setUser] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const [activate, setActivate] = useState(false);
    const toggle = (user) => {
        setUser(user);
        setActivate(!activate)
    };

    /**
     * @desc Method to get all users.
     */
    const getUsers = (page = null) => {
        const url = page === null ? process.env.REACT_APP_IZEVEN_URL + 'api/pending_users' : page;
        axiosApi.post(url, {email: email, name: name})
            .then(response => {
                setUsers(response.data.data);
                setPages(response.data.links)
            });
    }

    const activateIpa = (id) => {
        Swal.fire({
            title: 'Confirm Activation',
            text: 'Are you sure you activate ipa for this user',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#3f9ce8",
            confirmButtonText: "Yes, Continue",
            cancelButtonText: "No, Cancel"
        })
            .then(result => {
                if (result.isConfirmed) {
                    axiosApi.get(process.env.REACT_APP_IZEVEN_URL + 'api/ipa/activate/' + id)
                        .then(response => {
                            toastr.success(props.t('IPA activated with success.'), 'Success');
                            getUsers();
                        })
                        .catch(error => {
                            toastr.error(props.t('Server error, try again later.'), 'Error');
                            console.log(error);
                        })
                }
            });
    }

    let breadcrumbItems = [
        {title: "Odin", link: "/"},
        {title: "Izeven", link: "/izeven"},
        {title: "Users", link: "#"},
    ];

    useEffect(() => {
        getUsers();
    }, [email, name])

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="Users" breadcrumbItems={breadcrumbItems}/>

                    <Card>

                        <Row className="p-4">
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{props.t('Search by Name')}</Label>
                                    <InputGroup>
                                        <Input value={name} onChange={(e) => setName(e.target.value)} id="name"
                                               type="name"/>
                                        <Button onClick={() => getUsers()} color="primary">
                                            <i className="fa fa-search"/>
                                        </Button>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{props.t('Search by Email')}</Label>
                                    <InputGroup>
                                        <Input value={email} onChange={(e) => setEmail(e.target.value)} id="email"
                                               type="email"/>
                                        <Button onClick={() => getUsers()} color="primary">
                                            <i className="fa fa-search"/>
                                        </Button>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>

                        {
                            users.length ?
                                <>
                                    <div className="px-4">
                                        <Table responsive>
                                            <thead className="table-light">
                                            <tr>
                                                <th>{props.t('Name')}</th>
                                                <th>{props.t('Email')}</th>
                                                <th>{props.t('Plan')}</th>
                                                <th>{props.t('IPA')}</th>
                                                <th>{props.t('Date')}</th>
                                                <th>{props.t('Actions')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                users.map((user, index) =>
                                                    <tr key={index}>
                                                        <td>{user.name}</td>
                                                        <td>{user.email}</td>
                                                        <td>{user.subscription ? user.subscription.name : props.t('No Suscribed')}</td>
                                                        <td>{user.ipa ?
                                                            <span className="bg-primary badge badge-dark">{props.t('IPA')}</span> :
                                                            <span
                                                                className="bg-dark badge badge-dark">{props.t('NO IPA')}</span>}</td>
                                                        <td>{formatDate(user.created_at)}</td>
                                                        <td>
                                                            <div className="btn-group">
                                                                <button onClick={() => toggle(user)} disabled={user.subscription_id}
                                                                        className="btn btn-sm btn-primary">
                                                                    Subscription
                                                                </button>
                                                                <button onClick={() => activateIpa(user.id)} disabled={!!user.ipa} type="button"
                                                                        className="btn btn-sm btn-primary">
                                                                    IPA
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </Table>
                                    </div>

                                    <Row>
                                        <Col md={12}>
                                            <Pagination pages={pages} getMethod={getUsers}/>
                                        </Col>
                                    </Row>
                                </> : <NoMatches />
                        }
                    </Card>

                </Container>
            </div>

            {
                user ? <ActiveSubscription user={user} isOpen={activate} toggle={toggle} getUsers={getUsers} /> : <></>
            }
        </>
    )
}

export default withNamespaces()(PendingUsers);
