import React, { Component } from "react";
import { Row, Col, Alert, Button, Container, FormGroup, Label } from "reactstrap";

import { Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";


class ForgetPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <Row className="g-0">
                            <Col lg={4}>
                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                    <div className="text-center">
                                                        <div>
                                                            {/*<Link to="/" className="logo"><img src={logodark} height="20" alt="logo" /></Link>*/}
                                                        </div>

                                                        <h4 className="font-size-18 mt-4">Recuperar Contraseña</h4>
                                                        <p className="text-muted">Restaura tu contraseña de Odin.</p>
                                                    </div>

                                                    <div className="p-2 mt-5">

                                                        <AvForm className="form-horizontal">

                                                            <FormGroup className="auth-form-group-custom mb-4">
                                                                <i className="ri-mail-line auti-custom-input-icon"></i>
                                                                <Label for="email">Email</Label>
                                                                <AvField name="email" type="email" className="form-control" id="email" placeholder="Ingrese su email" />
                                                            </FormGroup>

                                                            <div className="mt-4 text-center">
                                                                <Button color="primary" className="w-md waves-effect waves-light" type="submit">{this.props.loading ? "Loading..." : "Enviar"}</Button>
                                                            </div>
                                                        </AvForm>
                                                    </div>

                                                    <div className="mt-5 text-center">
                                                        <p>© 2022 <a href="https://izeven.com/" target="_blank" >iZeven</a></p>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="authentication-bg">
                                    <div className="bg-overlay"></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default ForgetPasswordPage;
