import React, {useEffect, useState} from "react";
import {withNamespaces} from "react-i18next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
    Button,
    Card,
    CardBody,
    CardText,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import {Link, useParams} from "react-router-dom";
import {axiosApi} from "../../../helpers/Api";
import Subscription from "./Components/Subscription";
import Swal from "sweetalert2";
import ExpirationDate from "./Components/ExpirationDate";
import toastr from "toastr";

import classnames from "classnames";
import Data from "./Sections/Data";
import Wallet from "./Sections/Wallet";

const User = (props) => {

    let {id} = useParams();

    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);

    const [subscription, setSubscription] = useState(false);
    const toggleSubscription = () => setSubscription(!subscription);

    const [expiration, setExpiration] = useState(false);
    const toggleExpiration = () => setExpiration(!expiration);

    const getUser = () => {
        axiosApi.get(process.env.REACT_APP_IZEVEN_URL + 'api/user/' + id)
            .then(response => {
                setUser(response.data);
                setLoading(false);
            });
    }

    let breadcrumbItems = [
        {title: "Odin", link: "/"},
        {title: "Izeven", link: "/izeven"},
        {title: "Users", link: "#"},
        {title: "User", link: "#"},
    ];

    const toggleUser = () => {
        Swal.fire({
            title: 'Warning',
            text: 'Are you sure ' + (user.status !== 'Active' ? 'activate' : 'deactivate') + ' this user?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#3f9ce8",
            confirmButtonText: "Yes, Continue",
            cancelButtonText: "No, Cancel"
        })
            .then(result => {
                if (result.isConfirmed) {
                    axiosApi.post(process.env.REACT_APP_IZEVEN_URL + 'api/users/status', { id: user.id,  status: user.status === 'Active' ? 'Disabled' : 'Active' })
                        .then(response => {
                            toastr.success(props.t('User' + (!(user.status === 'Active') ? ' activated' : ' deactivated') + ' with success.'), 'Success');
                            getUser();
                        })
                        .catch(error => {
                            toastr.error(props.t('Server error, try again later.'), 'Error');
                            console.log(error);
                        })
                }
            })
    }

    const toggleIpa = () => {
        Swal.fire({
            title: 'Warning',
            text: 'Are you sure ' + (!user.ipa ? 'activate' : 'deactivate') + ' IPA for this user?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#3f9ce8",
            confirmButtonText: "Yes, Continue",
            cancelButtonText: "No, Cancel"
        })
            .then(result => {
                if (result.isConfirmed) {
                    axiosApi.get(process.env.REACT_APP_IZEVEN_URL + 'api/ipa/' + (!user.ipa ? 'activate/' : 'deactivate/') + user.id)
                        .then(response => {
                            toastr.success(props.t('IPA' + (!user.ipa ? ' activated' : ' deactivated') + ' with success.'), 'Success');
                            getUser();
                        })
                        .catch(error => {
                            toastr.error(props.t('Server error, try again later.'), 'Error');
                            console.log(error);
                        })
                }
            })
    }

    const [activeTabJustify, setActiveTabJustify] = useState("data");
    const toggleCustomJustified = (id) => setActiveTabJustify(id);

    useEffect(() => {
        getUser();
    }, [])

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    {
                        loading ? <></> :
                            <>
                                <Breadcrumbs title="Users" breadcrumbItems={breadcrumbItems}/>

                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md={2}>
                                                        <img src={user.profile_picture ?? 'https://izeven.sfo3.cdn.digitaloceanspaces.com/Izeven/Legacy/Api/izeven/images/avatar.jpg'} width="100%"/>
                                                        <Button className="mt-4" color="primary" style={{ width: '100%' }}>
                                                            <i className="fa fa-image"/> {' '} Change Picture
                                                        </Button>
                                                    </Col>
                                                    <Col md={10} style={{ paddingLeft: '40px' }}>
                                                        <Row>
                                                            <Col md={6}>
                                                                <h2>{user.name}</h2>
                                                            </Col>
                                                            <Col md={6} style={{ textAlign: 'right' }}>
                                                                <Button onClick={toggleUser} color={ user.status === 'Active' ? 'success' : 'danger' } className="fw-bold btn-rounded"> <small><i className="fa fa-dot-circle"/></small>
                                                                    { ' ' + user.status }
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        <p className="font-size-20 text-secondary"><i
                                                            className="fas fa-map-marker-alt"/> {user.complete_info ? user.complete_info.city : ''}, {user.complete_info ? user.complete_info.country : ''}
                                                        </p>

                                                        <hr/>

                                                        <Row>
                                                            <Col md={4}>
                                                                <small className="text-secondary">Subscription:</small>
                                                                <h5 className="text-primary">{user.subscription?.name} { user.subscription_id === 5 ? <></> : <i onClick={toggleSubscription} className="fas fa-edit"/> }</h5>
                                                            </Col>
                                                            <Col md={4}>
                                                                <small className="text-secondary">Ipa:</small>
                                                                <h5 className="text-primary">{user.ipa ? 'IPA' : 'Without IPA'} <i onClick={toggleIpa} className="fas fa-edit"/></h5>
                                                            </Col>
                                                            <Col md={4}>
                                                                <small className="text-secondary">Rank:</small>
                                                                <h5 className="text-primary">{ user.node.rank.name }</h5>
                                                            </Col>
                                                        </Row>

                                                        <Row className="mt-4">
                                                            <Col md={4}>
                                                                <small className="text-secondary">Subscribe Since:</small>
                                                                <h5 className="text-primary">{user.node.user.subscribed_since}</h5>
                                                            </Col>
                                                            <Col md={4}>
                                                                <small className="text-secondary">Expired at:</small>
                                                                <h5 className="text-primary">
                                                                    { user.monthly_renewal?.expiration } <i onClick={toggleExpiration} className="fas fa-edit"/>
                                                                </h5>
                                                            </Col><Col md={4}>
                                                            {
                                                                user.sponsor_id ?
                                                                    <>
                                                                        <small className="text-secondary">Sponsor:</small>
                                                                        <h5 className="text-primary">
                                                                            { user.sponsor.name } <a href={'/izeven/users/' + user.sponsor.id}><i className="fas fa-eye"/></a>
                                                                        </h5>
                                                                    </> : <></>
                                                            }
                                                        </Col>

                                                        </Row>

                                                        <hr/>

                                                        <Row>
                                                            <Col md={4}>
                                                                <small className="text-secondary">Email:</small>
                                                                <h5 className="text-primary">{user.email}</h5>
                                                            </Col>
                                                            <Col md={4}>
                                                                <small className="text-secondary">Phone:</small>
                                                                <h5 className="text-primary">{user.complete_info ? user.complete_info.phone : ''}</h5>
                                                            </Col>
                                                            <Col md={4}>
                                                                <small className="text-secondary">UID:</small>
                                                                <h5 className="text-primary">{ user.id }</h5>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <CardBody>
                                                <h2 className="text-primary">Network</h2>
                                                <hr/>
                                                <Row>
                                                    <Col md={4}>
                                                        <small className="text-secondary">Upline:</small>
                                                        {
                                                            user.node.parent_id ?
                                                                user.node.parent.user_id ?
                                                                    <h5 className="text-primary">{ user.node?.parent?.user?.name } <a href={'/izeven/users/' + user.node?.parent?.user?.id }><i className="fas fa-eye"/></a></h5>:
                                                                    <h5 className="text-primary">{ user.node?.parent?.invitation?.name } <small className="text-muted">(Reserved)</small></h5>
                                                                :<h5>Master Node</h5>
                                                        }
                                                    </Col>
                                                    <Col md={2}>
                                                        <small className="text-secondary">Left Points:</small>
                                                        <h5 className="text-primary">{ user.node.left_points }</h5>
                                                    </Col>
                                                    <Col md={2}>
                                                        <small className="text-secondary">Right Points:</small>
                                                        <h5 className="text-primary">{ user.node.right_points }</h5>
                                                    </Col>
                                                    <Col md={2}>
                                                        <small className="text-secondary">Month Directs:</small>
                                                        <h5 className="text-primary">{ user.node.month_direct.length }</h5>
                                                    </Col>
                                                    <Col md={2}>
                                                        <small className="text-secondary">Directs:</small>
                                                        <h5 className="text-primary">{ user.node.directs.length }</h5>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <Nav tabs className="nav-tabs-custom nav-justified">
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: activeTabJustify === "data"
                                                    })}
                                                    onClick={() => {
                                                        toggleCustomJustified("data");
                                                    }}
                                                >
                                                    <span className="d-sm-block">Data</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: activeTabJustify === "wallet"
                                                    })}
                                                    onClick={() => {
                                                        toggleCustomJustified("wallet");
                                                    }}
                                                >
                                                    <span className="d-sm-block">Wallet</span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </Col>
                                </Row>

                                <Card>
                                    <Row>
                                        <Col md={12}>
                                            <TabContent activeTab={activeTabJustify}>
                                                <TabPane tabId="data" className="p-3">
                                                    <Data getUser={getUser} user={user} />
                                                </TabPane>
                                                <TabPane tabId="wallet" className="p-3">
                                                    <Wallet user={user} />
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </Row>
                                </Card>
                            </>
                    }
                </Container>
            </div>

            <Subscription toggle={toggleSubscription} isOpen={subscription} plan={user.subscription_id} user={user} getUser={getUser}/>
            <ExpirationDate toggle={toggleExpiration} isOpen={expiration} user={user} getUser={getUser} />
        </>
    )
}

export default withNamespaces()(User);
