import React from "react";
import Select from 'react-select'

const ISelect = ({ options, placeholder, onChange, disabled = false, value = '', isMulti = false}) => {
    return (
        <Select
            value = {
                options.filter(option =>
                    option.value === value)
            }
            onChange={onChange}
            classNamePrefix="react-select"
            options={options}
            isLoading={!options.length}
            isDisabled={disabled}
            isMulti={isMulti}
            placeholder={placeholder}
        />
    )
}

export default ISelect;
