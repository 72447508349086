import {withNamespaces} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Button, Col, Row, Table} from "reactstrap";
import Create from "./Components/Create";
import {axiosApi} from "../../../../../helpers/Api";
import Edit from "./Components/Edit";

const Markets = (props) => {

    const [markets, setMarkets] = useState([]);

    const [create, setCreate] = useState(false);
    const toggle = () => setCreate(!create);

    const [market, setMarket] = useState(null);

    const [edit, setEdit] = useState(false);
    const toggleEdit = (market) => {
        if (market) {
            setMarket(market)
        }
        setEdit(!edit)
    };


    /**
     * @returns {Promise<void>}
     */
    const getMarkets = async () => {
        await axiosApi.get(process.env.REACT_APP_SIGNALS_API + 'api/markets')
            .then(response => {
                setMarkets(response.data);
            })
            .catch(error => console.error(error));
    }

    /**
     * @desc Method to delete a market.
     * @param id
     * @returns {Promise<void>}
     */
    const deleteMarket = async (id) => {
        await axiosApi.delete(process.env.REACT_APP_SIGNALS_API + 'api/markets/' + id)
            .then(response => {
                getMarkets();
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        getMarkets();
    }, []);

    return (
        <div>
            <Row className="justify-content-end mb-4">
                <Col md={2} className="text-end">
                    <Button onClick={toggle} className="btn-rounded" block color="primary"> <i
                        className="fas fa-plus"/> Add New</Button>
                </Col>
            </Row>

            <Table responsive>
                <thead className="table-light">
                <tr>
                    <th>{props.t('Market')}</th>
                    <th>{props.t('Assets')}</th>
                    <th>{props.t('Actions')}</th>
                </tr>
                </thead>
                <tbody>
                {
                    markets.map((market) =>
                        <tr>
                            <td>{ market.name }</td>
                            <td>{ market.assets.length }</td>
                            <td>
                                <div className="btn-group">
                                    <Row>
                                        <Col md={6}>
                                            <i onClick={() => toggleEdit(market)} style={{cursor: "pointer"}} className="fas fa-edit text-primary"></i>
                                        </Col>
                                        <Col md={6}>
                                            <i onClick={() => deleteMarket(market.id)} style={{cursor: "pointer"}} className="fas fa-trash text-danger"></i>
                                        </Col>
                                    </Row>
                                </div>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </Table>

            <Create toggle={toggle} isOpen={create} getMarkets={getMarkets}/>

            {
                market ? <Edit market={market} toggle={toggleEdit} isOpen={edit} getMarkets={getMarkets}/> : <></>
            }
        </div>
    )
}

export default withNamespaces()(Markets);
