import React, {Component, useEffect, useState} from "react";
import {Container, Row, Col, Card, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {withNamespaces} from "react-i18next";
import {axiosApi} from "../../../helpers/Api";
import {formatDate} from "../../../utils/format";
import Swal from "sweetalert2";
import toastr from "toastr";
import Reject from "./Components/Reject";
import {Link} from "react-router-dom";
import NoMatches from "../../../components/NoMatches";

const Kyc = (props) => {

    const [pendings, setPendings] = useState([]);

    const [pending, setPending] = useState(null);
    const [reject, setReject] = useState(false);
    const toggleReject = () => setReject(!reject);

    /**
     * @desc Method to get users with kyc approve pending.
     */
    const getPendings = () => {
        axiosApi.get(process.env.REACT_APP_IZEVEN_URL + 'api/kyc')
            .then(response => {
                setPendings(response.data);
            });
    }

    /**
     * @desc Method to approve user kyc
     * @param id
     */
    const approveKyc = (id) => {
        Swal.fire({
            title: 'Confirm Approval',
            text: 'Are you sure you approve this user\'s kyc?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#3f9ce8",
            confirmButtonText: "Yes, Continue",
            cancelButtonText: "No, Cancel"
        })
            .then(result => {
                if (result.isConfirmed) {
                    axiosApi.get(process.env.REACT_APP_IZEVEN_URL + 'api/kyc/approve/' + id)
                        .then(response => {
                            toastr.success(props.t('Kyc approved with success.'), 'Success');
                            getPendings();
                        })
                        .catch(error => {
                            toastr.success(props.t('Server error, try again later.'), 'Success');
                            console.log(error);
                        })
                }
            });
    }

    let breadcrumbItems = [
        {title: "Odin", link: "/"},
        {title: "Izeven", link: "/izeven"},
        {title: "Kyc", link: "#"},
    ];

    useEffect(() => {
        getPendings();
    }, []);

    useEffect(() => {
        if (pending) {
            toggleReject();
        }
    }, [pending]);

    return (
        <>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="Kyc" breadcrumbItems={breadcrumbItems}/>

                    <Card>
                        {
                            pendings.length ?
                                <div className="px-4 py-4">
                                    <Table responsive>
                                        <thead className="table-light">
                                        <tr>
                                            <th className="izeven-text">{props.t('Id')}</th>
                                            <th className="izeven-text">{props.t('User')}</th>
                                            <th className="izeven-text">{props.t('ID Card')}</th>
                                            <th className="izeven-text">{props.t('Validation Doc')}</th>
                                            <th className="izeven-text">{props.t('Passport')}</th>
                                            <th className="izeven-text">{props.t('Date')}</th>
                                            <th className="izeven-text">{props.t('Actions')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            pendings.map((pending, index) =>
                                                <tr>
                                                    <td>{ index + 1 }</td>
                                                    <td><Link to={'/izeven/users/' + pending.id}>{ pending.name }</Link></td>
                                                    <td>
                                                        {
                                                            pending.kyc.identification_card ?
                                                                <a href={ pending.kyc.identification_card } target="blank"><i className="fa fa-eye"/> ID Card</a> : <span className="bg-dark badge badge-dark">{ props.t('No Upload') }</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            pending.kyc.validation_address ?
                                                                <a href={ pending.kyc.validation_address } target="blank"><i className="fa fa-eye"/> Validation Address</a> : <span className="bg-dark badge badge-dark">{ props.t('No Upload') }</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            pending.kyc.passport ?
                                                                <a href={ pending.kyc.passport } target="blank"><i className="fa fa-eye"/> ID Card</a> : <span className="bg-dark badge badge-dark">{ props.t('No Upload') }</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        { formatDate(pending.kyc.updated_at) }
                                                    </td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <button onClick={() => approveKyc(pending.id)} type="button" className="btn btn-sm btn-primary js-tooltip-enabled">
                                                                <i className="fas fa-check"></i> {'  '} Accept
                                                            </button>
                                                            <button onClick={() => setPending(pending.id)} type="button" className="btn btn-sm btn-danger js-tooltip-enabled">
                                                                <i className="fa fa-times"></i> {'  '} Reject
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                </div> : <NoMatches/>
                        }
                    </Card>

                </Container>
            </div>

            <Reject isOpen={reject} toggle={toggleReject} id={pending} />
        </>
    )
}

export default withNamespaces()(Kyc);
