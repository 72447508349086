import React, {useEffect, useRef, useState} from "react";
import {withNamespaces} from "react-i18next";
import {useParams} from "react-router-dom";
import {axiosApi} from "../../../helpers/Api";
import {Button, Card, CardBody, Col, Container, FormGroup, FormText, Input, Label, Row} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Details from "../Course/Sections/Details";
import Lessons from "../Course/Sections/Lessons";
import JoditEditor from "jodit-react";
import Resources from "./Components/Resources";
import Quiz from "../Quiz";

const Lesson = (props) => {

    let {id} = useParams();

    const [lesson, setLesson] = useState({});
    const [loading, setLoading] = useState(true);

    const [title, setTitle] = useState('');
    const [video, setVideo] = useState('');
    const editorContent = useRef(null);
    const [content, setContent] = useState('');

    const {level} = lesson ?? {};
    const {course} = level ?? {};

    const [quiz, setQuiz] = useState(false);
    const toggleQuiz = async () => {

        if (!lesson.test) {
            await axiosApi.post(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/test', { title: "Quiz of lesson: " + lesson.title, lesson_id: lesson.id })
                .then(response => {
                    getLesson();
                })
                .catch(error => console.error(error));
        }

        setQuiz(!quiz)
    };

    let breadcrumbItems = [
        {title: course ? course.title : '', link: `/mindpro/course/${course ? course.id : ''}`},
        {title: level ? level.name : '', link: "#"},
        {title: lesson.title ?? '', link: "#"}
    ];

    const getLesson = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/lesson/' + id)
            .then(response => {
                setLesson(response.data);
                setLoading(false);
            })
            .catch(error => console.log(error));
    }

    const save = async () => {
        let data = {
            title: title,
            video: video,
            content: content
        }

        await axiosApi.put(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/lesson/' + id, data)
            .then(response => {
                getLesson();
            })
            .catch(error => console.log(error));
    }

    useEffect(() => {
        getLesson();
    }, [])

    useEffect(() => {
        setTitle(lesson.title);
        setVideo(lesson.video);
        setContent(lesson.content)
    }, [lesson]);


    return (
        <div className="page-content">
            {
                !loading ?
                    <Container fluid>
                        <Breadcrumbs title={ lesson.title ?? '' } breadcrumbItems={breadcrumbItems}/>

                        <Row>
                            <Col md={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <h3 className="text-primary">Details</h3>
                                            </Col>
                                            <Col style={{ textAlign: "right" }}>
                                                <Button onClick={toggleQuiz} color="primary" outline={!lesson.test} ><i className="fa fa-edit"/> { lesson.test ? 'Edit Quiz' : 'Add Quiz' }</Button>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <FormGroup>
                                            <Label>{props.t('Title')}</Label>
                                            <Input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>{props.t('Video')}</Label>
                                            <Input type="text" value={video} onChange={(e) => setVideo(e.target.value)} />
                                            <FormText>Insert the url of the video, remember that you must copy from <strong>https</strong>.</FormText>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>{props.t('Content')}</Label>
                                            <JoditEditor
                                                ref={editorContent}
                                                config={{
                                                    placeholder: 'Write the content of the lesson'
                                                }}
                                                value={content}
                                                tabIndex={1}
                                                onBlur={newContent => setContent(newContent)}
                                            />
                                        </FormGroup>

                                        <Row className="mx-1 text-right">
                                            <Button onClick={save} color="primary">
                                                Save
                                            </Button>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={12}>
                                <Resources lesson={lesson} />
                            </Col>
                        </Row>
                        <Quiz quiz={lesson.test ?? {} } toggle={toggleQuiz} isOpen={quiz} />
                    </Container>     : <></>
            }
        </div>
    )
}

export default withNamespaces()(Lesson);
