import React, {useEffect, useRef, useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import convertToBase64 from "../../../../helpers/Convert/base64";
import {axiosApi} from "../../../../helpers/Api";
import ISelect from "../../../../components/Select";
import Select from "react-select";

const Create = (props) => {

    const [educatorsList, setEducatorsList] = useState([]);

    const [title, setTitle] = useState('');
    const [educators, setEducators] = useState([]);
    const [time, setTime] = useState('');
    const [description, setDescription] = useState('');
    const [cover, setCover] = useState('');

    const coverRef = useRef();

    const handleFile = (e, setter) => {
        const file = e.target.files[0];
        convertToBase64(file)
            .then(response => {
              setter(response);
            })
    }

    const getEducators = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/educators')
            .then(response => {
                setEducatorsList(response.data.map(element => ({ label: element.name, value: element.id })));
            })
            .catch(error => console.error(error));
    }

    const save = async () => {
        let data = {
            cover: cover,
            title: title,
            description: description,
            duration: time,
            educators: educators
        }

        await axiosApi.post(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/course', data)
            .then(response => {
                props.getCourses();
                props.toggle();
            })
            .catch(error => console.error(error));
    }

    const validate = () => {
        return !cover && !title && !description && !time;
    }

    useEffect(() => {
        getEducators();
    }, [])

    return (
        <Modal centered toggle={props.toggle} isOpen={props.isOpen}>
            <ModalHeader charCode="Y" toggle={props.toggle}/>
            <ModalBody>
                <h3 className="mb-3 text-primary">{ props.t('Add Course') }</h3>
                <hr/>
                <FormGroup floating className="mb-1">
                    <Label>{ props.t('Title') }</Label>
                    <Input type="text" value={title} onChange={(e) => setTitle(e.target.value)}/>
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{ props.t('Educators') }</Label>
                    <Select options={educatorsList} isMulti onChange={(e) => setEducators(e.map(e => e.value))} placeholder="Select the educators of course..." />
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{ props.t('Time') }</Label>
                    <Input type="text" value={time} onChange={(e) => setTime(e.target.value)} />
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{ props.t('Description') }</Label>
                    <Input type="textarea" value={description} onChange={(e) => setDescription(e.target.value)} />
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{ props.t('Cover') }</Label>
                    <Row className="mx-1">
                        <Button onClick={() => coverRef.current.click()} color="primary" outline={!cover}> { !cover ? <><i className="fa fa-upload"/> Upload Image</> : <><i className="fa fa-check"/> Image Uploaded</> }</Button>
                        <input type="file" ref={coverRef} accept="image/png, image/gif, image/jpeg" onChange={(e) => handleFile(e, setCover)} hidden/>
                    </Row>
                    { cover ? <FormText>To change the uploaded click again.</FormText> : <></> }
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button onClick={save} disabled={validate()} color="primary">{props.t('Add')}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default withNamespaces()(Create);
