import React, {useEffect, useState} from 'react';
import {withNamespaces} from "react-i18next";
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {axiosApi} from "../../../../../../helpers/Api";

const Update = (props) => {

    // Object with form properties.
    const [data, setData] = useState({
        date: props.mentoring.date,
        course_id: props.mentoring.course_id,
        educator_id: props.mentoring.educator_id,
        title: props.mentoring.title,
        'link': props.mentoring.link
    });

    // List of educators.
    const [educators, setEducators] = useState([]);

    /**
     * @desc Method to handle the input changes.
     * @param event
     */
    const handleInputChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }

    /**
     * @desc Method to get educators list.
     * @returns {Promise<void>}
     */
    const getEducators = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/educators')
            .then(response => {
                setEducators(response.data);
            })
            .catch(error => console.error(error));
    }

    /**
     * @desc Method to validate the fields of the form.
     * @returns {boolean}
     */
    const validate = () => {
        return !(data.date && data.educator_id && data.title && data.link);
    }

    /**
     * @desc Method to save the private mentoring.
     * @returns {Promise<void>}
     */
    const save = async () => {
        await axiosApi.put(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/private-mentorings/' + props.mentoring.id, data)
            .then(response => {
                props.getMentorings();
                props.toggle();
            })
            .catch(error => console.log(error));
    }

    useEffect(() => {
        getEducators()
    }, [])

    return (
        <Modal centered toggle={props.toggle} isOpen={props.isOpen}>
            <ModalHeader charCode="Y" toggle={props.toggle}>
                <span className="text-primary">Edit Mentoring</span>
            </ModalHeader>
            <ModalBody>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Date')}</Label>
                    <Input type="date" name="date" value={data.date} onChange={handleInputChange}/>
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Title')}</Label>
                    <Input type="text" name="title" value={data.title} onChange={handleInputChange}/>
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Educator')}</Label>
                    <Input type="select" name="educator_id" value={data.educator_id} onChange={handleInputChange}>
                        <option value=""></option>
                        {
                            educators.map((educator) => <option key={educator.id}
                                                                value={educator.id}>{educator.name}</option>)
                        }
                    </Input>
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Link')}</Label>
                    <Input type="text" name="link" value={data.link} onChange={handleInputChange}/>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button onClick={save} disabled={validate()} color="primary">{props.t('Save')}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default withNamespaces()(Update);
