import React, {Component, useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import {connect, useDispatch} from 'react-redux';

import {logout} from "../../features/userSlice";

const Logout = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logout())
            .then(() => props.history.push('/login'));
    })

    return (
        <>S
            <h1>&nbsp;</h1>
        </>
    )
}

export default withRouter(Logout);

