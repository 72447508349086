import React, {useEffect, useState} from "react";
import {Button, Col, FormGroup, Input, InputGroup, Label, Row, Table} from "reactstrap";
import {Link} from "react-router-dom";
import {formatDate} from "../../../../../utils/format";
import Pagination from "../../../../../components/Common/Pagination";
import NoMatches from "../../../../../components/NoMatches";
import {withNamespaces} from "react-i18next";
import {axiosApi} from "../../../../../helpers/Api";
import Create from "./Components/Create";
import Swal from "sweetalert2";
import toastr from "toastr";

const Lessons = (props) => {

    const [educators, setEducators] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [pages, setPages] = useState([])

    const [title, setTitle] = useState('');
    const [educator, setEducator] = useState('');

    const [create, setCreate] = useState(false);
    const toggleCreate = () => setCreate(!create);

    const getEducators = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/educators')
            .then(response => {
                setEducators(response.data);
            })
            .catch(error => console.log(error));
    }

    const getLessons = async (page = null) => {
        const url = page === null ? process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/recorded-lessons' : page;
        await axiosApi.post(url, {title: title, educator: educator})
            .then(response => {
                setLessons(response.data.data);
                setPages(response.data.links);
            })
            .catch(error => console.error(error));
    }

    const toggle = () => {
    };

    const deleteLesson = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure you want to delete this record?, this action can not reversed',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#3f9ce8",
            confirmButtonText: "Yes, Continue",
            cancelButtonText: "No, Cancel"
        })
            .then(result => {
                if (result.isConfirmed) {
                    axiosApi.delete(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/recorded-lessons/' + id)
                        .then(response => {
                            toastr.success(props.t('Lesson delete with success.'), 'Success');
                            getLessons();
                        })
                        .catch(error => {
                            toastr.success(props.t('Server error, try again later.'), 'Success');
                            console.log(error);
                        })
                }
            });
    }

    useEffect(() => {
        getLessons();
        getEducators();
    }, [])

    return (
        <div className="">
            <Row className="p-4">
                <Col md={4}>
                    <FormGroup>
                        <Label>{props.t('Search by Title')}</Label>
                        <InputGroup>
                            <Input value={title} onChange={(e) => setTitle(e.target.value)} id="title"
                                   type="name"/>
                            <Button onClick={() => getLessons()} color="primary">
                                <i className="fa fa-search"/>
                            </Button>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label>{props.t('Search by Educator')}</Label>
                        <InputGroup>
                            <Input value={educator} onChange={(e) => setEducator(e.target.value)} id="educator"
                                   type="select">
                                <option></option>
                                {
                                    educators.map((educator, index) => (
                                        <option value={educator.id}>{educator.name}</option>
                                    ))
                                }
                            </Input>
                            <Button onClick={() => getLessons()} color="primary">
                                <i className="fa fa-search"/>
                            </Button>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label style={{color: 'white'}}>Add New</Label>
                        <Button onClick={toggleCreate} style={{width: "100%"}} color="primary"> <i
                            className="fa fa-plus"/> Add New</Button>
                    </FormGroup>
                </Col>
            </Row>

            {
                lessons.length ?
                    <>
                        <div className="px-4">
                            <Table responsive>
                                <thead className="table-light">
                                <tr>
                                    <th>#</th>
                                    <th>{props.t('Title')}</th>
                                    <th>{props.t('Date')}</th>
                                    <th>{props.t('Link')}</th>
                                    <th>{props.t('Educator')}</th>
                                    <th>{props.t('Min Plan')}</th>
                                    <th>{props.t('Actions')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    lessons.map((lesson, index) => (
                                        <tr key={index}>
                                            <td>{lesson.id}</td>
                                            <td>{lesson.title}</td>
                                            <td>{formatDate(lesson.date)}</td>
                                            <td><a className="btn btn-primary" href={lesson.link} target="_blank"> <i className="fa fa-eye"/> See Class </a></td>
                                            <td>{lesson.educators.map((educator, index) => lesson.educators.length === index + 1 ? educator.name : educator.name + ', ')}</td>
                                            <td>{lesson.plan.name}</td>
                                            <td>
                                                <i style={{cursor: "pointer"}} className="fa fa-edit text-primary"/>{'  '}
                                                <i style={{cursor: "pointer"}} onClick={() => deleteLesson(lesson.id)} className="fa fa-trash text-danger"/></td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </div>

                        <Row>
                            <Col md={12}>
                                <Pagination pages={pages} getMethod={getLessons}/>
                            </Col>
                        </Row>
                    </> : <NoMatches/>

            }
            <Create toggle={toggleCreate} isOpen={create} getLessons={getLessons}/>
        </div>
    )
}

export default withNamespaces()(Lessons);
