import React, {useEffect, useState} from "react";
import {Card, Col, Container, Form, FormGroup, Input, Label, Row, Table} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {formatCurrency, formatDate} from "../../../utils/format";
import {axiosApi} from "../../../helpers/Api";
import {withNamespaces} from "react-i18next";
import Pagination from "../../../components/Common/Pagination";
import Swal from "sweetalert2";
import toastr from "toastr";
import {Link} from "react-router-dom";
import NoMatches from "../../../components/NoMatches";

const Withdrawals = (props) => {

    const [withdrawals, setWithdrawals] = useState([]);

    /**
     * @desc Method to get all withdrawals.
     */
    const getWithdrawals = (page = null) => {
        axiosApi.get(process.env.REACT_APP_IZEVEN_URL + 'api/withdrawals')
            .then(response => {
                console.log(response.data);
                setWithdrawals(response.data.withdrawals);
            });
    }

    /**
     * @desc Method to approve user withdrawal
     * @param id
     */
    const approveWithdrawal = (id) => {
        Swal.fire({
            title: 'Confirm Withdrawal',
            text: 'Are you sure you approve this user\'s withdrawal?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#3f9ce8",
            confirmButtonText: "Yes, Continue",
            cancelButtonText: "No, Cancel"
        })
            .then(result => {
                if (result.isConfirmed) {
                    axiosApi.get(process.env.REACT_APP_IZEVEN_URL + 'api/withdrawals/approve/' + id)
                        .then(response => {
                            toastr.success(props.t('Kyc approved with success.'), 'Success');
                            getWithdrawals();
                        })
                        .catch(error => {
                            toastr.success(props.t('Server error, try again later.'), 'Success');
                            console.log(error);
                        })
                }
            });
    }

    /**
     * @desc Method to reject user withdraw
     * @param id
     */
    const rejectKyc = (id) => {
        Swal.fire({
            title: 'Reject Kyc',
            text: 'Are you sure you reject this user\'s withdraw?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#3f9ce8",
            confirmButtonText: "Yes, Continue",
            cancelButtonText: "No, Cancel"
        })
            .then(result => {
                if (result.isConfirmed) {
                    axiosApi.get(process.env.REACT_APP_IZEVEN_URL + 'api/withdrawals/reject/' + id)
                        .then(response => {
                            toastr.success(props.t('Withdrawal rejected with success.'), 'Success');
                            getWithdrawals();
                        })
                        .catch(error => {
                            toastr.success(props.t('Server error, try again later.'), 'Error');
                            console.log(error);
                        })
                }
            });
    }

    let breadcrumbItems = [
        {title: "Odin", link: "/"},
        {title: "Izeven", link: "/izeven"},
        {title: "Withdrawals", link: "#"},
    ];

    useEffect(() => {
        getWithdrawals();
    }, []);

    return (
        <>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="Withdrawals" breadcrumbItems={breadcrumbItems}/>

                    <Card>
                        {
                            withdrawals.length ?
                                <div className="px-4 py-4">
                                    <Table responsive>
                                        <thead className="table-light">
                                        <tr>
                                            <th>{props.t('User')}</th>
                                            <th>{props.t('Email')}</th>
                                            <th>{props.t('Amount')}</th>
                                            <th>{props.t('Bank Cert')}</th>
                                            <th>{props.t('KYC')}</th>
                                            <th>{props.t('Date')}</th>
                                            <th>{props.t('Extract')}</th>
                                            <th>{props.t('Actions')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            withdrawals.map((withdraw, index) =>
                                                <tr key={index}>
                                                    <td>{withdraw.name}</td>
                                                    <td>{withdraw.email}</td>
                                                    <td>{formatCurrency(withdraw.transaction.amount)}</td>
                                                    <td><a href={withdraw.bank_certificate} target="blank"> <i
                                                        className="fa fa-eye"/> See </a></td>
                                                    <td>{withdraw.kyc ? withdraw.kyc ? <span
                                                            className="bg-success badge badge-dark">{props.t('APPROVED')}</span> : withdraw.kyc.message ?
                                                            <span
                                                                className="bg-dark badge badge-dark">{props.t('REJECTED')}</span> :
                                                            <span
                                                                className="bg-warning badge badge-dark">{props.t('PENDING')}</span> :
                                                        <span
                                                            className="bg-secondary badge badge-dark">{props.t('NO UP')}</span>}</td>
                                                    <td>{formatDate(withdraw.transaction.created_at)}</td>
                                                    <td>
                                                        <a href={process.env.REACT_APP_IZEVEN_URL + 'api/transactions/' + withdraw.id}
                                                           className="btn btn-sm btn-primary js-tooltip-enabled">
                                                            <i className="fa fa-download"></i> Descargar
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <button onClick={() => { approveWithdrawal(withdraw.id) }} type="button" className="btn btn-sm btn-primary js-tooltip-enabled">
                                                                <i className="fas fa-check"></i> {'  '} Accept
                                                            </button>
                                                            <button onClick={() => { rejectKyc(withdraw.id) }} type="button" className="btn btn-sm btn-danger js-tooltip-enabled">
                                                                <i className="fa fa-times"></i> {'  '} Reject
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                </div> : <NoMatches/>
                        }
                    </Card>

                </Container>
            </div>
        </>
    )
}

export default withNamespaces()(Withdrawals);
