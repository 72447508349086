import React, {useEffect, useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Card, CardBody, FormGroup, Input, Label, Row} from "reactstrap";
import {axiosApi} from "../../../../helpers/Api";
import Select from "react-select";

const Form = (props) => {

    const [educatorsList, setEducatorsList] = useState([]);

    const [title, setTitle] = useState('');
    const [educators, setEducators] = useState([]);
    const [video, setVideo] = useState('');
    const [chat, setChat] = useState('');

    const getEducators = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/educators')
            .then(response => {
                setEducatorsList(response.data.map(element => ({ label: element.name, value: element.id })));
            })
            .catch(error => console.error(error));
    }

    const save = async () => {
        let data = {
            title: title,
            educators: educators,
            url: video,
            chat: chat,
            status: 'Active'
        }

        await axiosApi.post(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/masterclass', data)
            .then(response => {
                setTitle('');
                setEducators('');
                setVideo('');
                setChat('');
                props.getClasses();
            })
            .catch(error => console.log(error));
    }

    useEffect(() => {
        getEducators();
    }, [])

    return (
        <Card>
            <CardBody>
                <h3 className="mb-3 text-primary">{ props.t('Start Masterclass') }</h3>
                <hr/>
                <FormGroup floating className="mb-1">
                    <Label>{ props.t('Title') }</Label>
                    <Input value={title} onChange={(e) => setTitle(e.target.value)} type="text" />
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{ props.t('Educators') }</Label>
                    <Select options={educatorsList} isMulti onChange={(e) => setEducators(e.map(e => e.value))} placeholder="Select the educators of masterclass..." />
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{ props.t('Video URL') }</Label>
                    <Input value={video} onChange={(e) => setVideo(e.target.value)} type="text" />
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{ props.t('Chat URL') }</Label>
                    <Input value={chat} onChange={(e) => setChat(e.target.value)} type="text" />
                </FormGroup>
                <hr/>
                <Row className="mx-1 text-right">
                    <Button onClick={save} color="primary">
                        Save
                    </Button>
                </Row>
            </CardBody>
        </Card>
    )
}

export default withNamespaces()(Form);
