import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { axiosApi } from "../../../../../helpers/Api";

const PointsReport = (props) => {
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const generate = () => {
    let data = {
      from: from,
      to: to,
    };

    axiosApi
      .post(
        process.env.REACT_APP_NETWORK_SERVICE_URL + "api/points/report",
        data,
        { responseType: "blob" }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        let date = new Date();
        date = `${date.getDate()}${date.getMonth() + 1}${date.getFullYear()}${date.getHours()}${date.getMinutes()}`
        link.setAttribute("download", `points_movements_${date}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  return (
    <Card>
      <CardBody>
        <h3 className="text-primary">Points Movements</h3>
        <hr />
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>{props.t("From")}</Label>
              <Input
                onChange={(e) => setFrom(e.target.value)}
                id="from"
                type="date"
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>{props.t("To")}</Label>
              <Input
                onChange={(e) => setTo(e.target.value)}
                id="to"
                type="date"
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label className="text-white">Gen</Label>
              <Row>
                <Button
                  disabled={!(from && to)}
                  onClick={generate}
                  block
                  color="primary"
                >
                  Generate
                </Button>
              </Row>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default withNamespaces()(PointsReport);
