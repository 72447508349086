import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";

const Add = (props) => {

    const [title, setTitle] = useState(null);
    const [speaker, setSpeaker] = useState(null);
    const [rank, setRank] = useState(null);
    const [url, setUrl] = useState(null);
    const [date, setDate] = useState(null);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);

    return (
        <Modal centered toggle={props.toggle} isOpen={props.isOpen}>
            <ModalHeader charCode="Y" toggle={props.toggle}/>
            <ModalBody>
                <h3 className="mb-3 text-primary">{props.t('Add Event')}</h3>
                <hr/>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Title')}</Label>
                    <Input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Speaker')}</Label>
                    <Input type="text" value={speaker} onChange={(e) => setSpeaker(e.target.value)} />
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Rank')}</Label>
                    <Input type="text" value={rank} onChange={(e) => setRank(e.target.value)} />
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Url')}</Label>
                    <Input type="text" value={url} onChange={(e) => setUrl(e.target.value)} />
                </FormGroup>
                <FormGroup floating className="mb-1">
                    <Label>{props.t('Date')}</Label>
                    <Input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                </FormGroup>
                <FormGroup floating className="mb-1 mt-2">
                    <Row>
                        <Col md={6}>
                            <Label>{props.t('Start at')}</Label>
                            <Input type="time" value={start} onChange={(e) => setStart(e.target.value)} />
                        </Col>
                        <Col md={6}>
                            <Label>{props.t('End at')}</Label>
                            <Input type="time" value={end} onChange={(e) => setEnd(e.target.value)} />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup floating className="mb-1 mt-2">
                    <Label>{props.t('Cover')}</Label>
                    <Row className="mx-1">
                        <Button block color="primary" outline>Add Cover</Button>
                    </Row>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary">{props.t('Add')}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default withNamespaces()(Add);
