import React from "react";
import {withNamespaces} from "react-i18next";
import {Col, Row} from "reactstrap";

const Multimedia = (props) => {
    return (
        <Row>
            {
                props.project.images.map(image =>
                    <Col className="mb-4" md={6}>
                        <img width="100%" src={image.path}/>
                    </Col>
                )
            }
        </Row>
    )
}

export default withNamespaces()(Multimedia);
