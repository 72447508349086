import React, {useEffect, useState} from "react";
import {withNamespaces} from "react-i18next";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, CardHeader, Col, Collapse, Row, Table} from "reactstrap";
import empty from "../../../../assets/images/empty-box.png";
import Lesson from "./Lesson";
import {formatDate} from "../../../../utils/format";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {axiosApi} from "../../../../helpers/Api";
import Quiz from "../../Quiz";

const Level = (props) => {

    const {level} = props;

    const [lessons, setLessons] = useState([]);

    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);

    const [create, setCreate] = useState(false);
    const toggleCreate = () => setCreate(!create);

    const [quiz, setQuiz] = useState(false);
    const toggleQuiz = async () => {

        if (!level.test) {
            await axiosApi.post(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/test', { title: "Quiz of level: " + level.name, level_id: level.id })
                .then(response => {
                    props.getCourse();
                })
                .catch(error => console.error(error));
        }

        setQuiz(!quiz);
    }

    const handleDragEnd = async (e) => {
        if(!e.destination) return;
        let tempData = Array.from(lessons);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        await setLessons(tempData);
        updateOrder(lessons);
    };

    const updateOrder = async () => {
        await axiosApi.post(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/lesson/order', { lessons: lessons })
            .then(response => {
                console.log(response);
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        setLessons(props.lessons);
    }, [props]);

    useEffect(() => {

    }, [lessons]);

    return (
        <>
            <Card className="mb-1 shadow-none">
                <Link to="#" onClick={toggle} style={{cursor: "pointer"}} className="text-dark">
                    <CardHeader id="headingOne">
                        <h6 className="m-0 font-14">
                            {level.name + ` (${lessons.length})`}
                            <i className={open ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                        </h6>
                    </CardHeader>
                </Link>
                <Collapse isOpen={open}>
                    <CardBody>
                        {
                            lessons.length ?
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <Table>
                                        <Droppable droppableId="droppable-1">
                                            {(provider) => (
                                                <tbody ref={provider.innerRef} {...provider.droppableProps}>
                                                {
                                                    lessons.map((lesson, index) => (
                                                        <Draggable key={lesson.title} draggableId={lesson.title}
                                                                   index={index}>
                                                            {(provider) => (
                                                                <tr {...provider.draggableProps} ref={provider.innerRef}>
                                                                    <td {...provider.dragHandleProps} style={{width: "5%"}}><i
                                                                        className="fa fa-align-justify handle"/>
                                                                    </td>
                                                                    <td style={{width: "5%"}}>{index + 1}</td>
                                                                    <td>{lesson.title}</td>
                                                                    <td>{formatDate(lesson.created_at)}</td>
                                                                    <td>
                                                                        <div className="btn-group">
                                                                            <Row>
                                                                                <Col md={6}>
                                                                                    <Link
                                                                                        to={`/mindpro/lesson/${lesson.id}`}><i
                                                                                        className="fas fa-edit text-primary"></i></Link>
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <i className="fas fa-trash text-danger"></i>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                    ))
                                                }
                                                {provider.placeholder}
                                                </tbody>
                                            )}
                                        </Droppable>
                                    </Table>
                                </DragDropContext>
                                :
                                <div className="text-center">
                                    <Row className="justify-content-center">
                                        <Col className="text-center mt-2 mb-2" md={4}>
                                            <img className="mt-2 mb-2" width={150} src={empty} alt="empty"/>
                                            <h2 className="title">NO LESSONS</h2>
                                        </Col>
                                    </Row>
                                    <Button onClick={toggleCreate} className="btn-rounded" block
                                            color="primary"> <i className="fas fa-plus"/>Add New</Button>
                                </div>
                        }
                        {
                            !lessons.length ? <></> : <Row>
                                <Button onClick={toggleCreate} className="btn-rounded" block
                                        color="primary"> <i className="fas fa-plus"/> {"  "} Add New</Button>
                                <Button onClick={toggleQuiz} className="btn-rounded mt-1" block
                                        color="primary" outline={!level.test}><i className="fa fa-edit"/> { level.test ? 'Edit Quiz' : 'Add Quiz' }</Button>
                            </Row>
                        }
                    </CardBody>
                </Collapse>
            </Card>
            <Lesson getCourse={props.getCourse} levelId={level.id} isOpen={create} toggle={toggleCreate}/>
            <Quiz quiz={level.test ?? {} } toggle={toggleQuiz} isOpen={quiz} />
        </>
    )
}

export default withNamespaces()(Level);
