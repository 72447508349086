import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

import Kyc from "../pages/Izeven/Kyc";
import Users from "../pages/Izeven/Users";
import Withdrawals from "../pages/Izeven/Withdrawals";
import InviteLinks from "../pages/Izeven/InviteLinks";
import PendingUsers from "../pages/Izeven/PendingUsers";
import User from "../pages/Izeven/User";
import Projects from "../pages/Terra/Projects";
import Project from "../pages/Terra/Project";
import AddProject from "../pages/Terra/AddProject";
import AgProjects from "../pages/Agronomy/Projects";
import AgProject from "../pages/Agronomy/Project";
import AgAddProject from "../pages/Agronomy/AddProject";
import Radio from "../pages/Avanx/Radio";
import Academy from "../pages/Avanx/Academy";
import Calendar from "../pages/Avanx/Calendar";
import Reports from "../pages/Izeven/Reports";
import Courses from "../pages/Mindpro/Courses";
import Educators from "../pages/Mindpro/Educators";
import Course from "../pages/Mindpro/Course";
import Lesson from "../pages/Mindpro/Lesson";
import Masterclass from "../pages/Mindpro/Masterclass";
import RecordedLessons from "../pages/Mindpro/RecordedLessons";
import Signals from "../pages/Mindpro/Signals";
import Iup from "../pages/Iup";
import Network from "../pages/Izeven/Network";
import SignalsEducators from "../pages/Signals/Educators";

const authProtectedRoutes = [

	{ path: "/dashboard", component: Dashboard },

	// IZEVEN
	{ path: "/izeven/kyc", component: Kyc },
	{ path: "/izeven/users/:id", component: User },
	{ path: "/izeven/users", component: Users },
	{ path: "/izeven/withdrawals", component: Withdrawals },
	{ path: "/izeven/invite-links", component: InviteLinks },
	{ path: "/izeven/pending-users", component: PendingUsers },
	{ path: "/izeven/Reports", component: Reports },
	{ path: "/izeven/network", component: Network },

	// TERRA
	{path: "/terra/projects/add", component: AddProject},
	{path: "/terra/projects/:id", component: Project},
	{path: "/terra/projects", component: Projects},

	// AGRONOMY
	{path: "/agronomy/projects/add", component: AgAddProject},
	{path: "/agronomy/projects/:id", component: AgProject},
	{path: "/agronomy/projects", component: AgProjects},

	// AVANX
	{path: "/avanx/radio", component: Radio},
	{path: "/avanx/academy", component: Academy},
	{path: "/avanx/calendar", component: Calendar},

	// MINDPRO
	{path: "/mindpro/courses", component: Courses},
    {path: "/mindpro/course/:id", component: Course},
	{path: "/mindpro/lesson/:id", component: Lesson},
	{path: "/mindpro/educators", component: Educators},
	{path: "/mindpro/masterclass", component: Masterclass},
	{path: "/mindpro/recorded-lessons", component: RecordedLessons},

	// Signals
	{path: "/signals/signals", component: Signals },
	{path: "/signals/educators", component: SignalsEducators},

	// IUP
	{path: "/iup", component: Iup},

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
];

export { authProtectedRoutes, publicRoutes };
