import React, {useEffect, useState} from "react";
import {Button, Card, Col, Container, Form, FormGroup, Input, Label, Row, Table} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {formatDate} from "../../../utils/format";
import {axiosApi} from "../../../helpers/Api";
import {withNamespaces} from "react-i18next";
import Pagination from "../../../components/Common/Pagination";
import {Link} from "react-router-dom";

const Projects = (props) => {

    const [projects, setProjects] = useState([]);

    /**
     * @desc Method to get all projects.
     */
    const getProjects = () => {
        axiosApi.get(process.env.REACT_APP_TERRA_URL + 'api/projects')
            .then(response => {
                setProjects(response.data.projects);
            });
    }


    let breadcrumbItems = [
        {title: "Odin", link: "/"},
        {title: "Terra", link: "/izeven"},
        {title: "Projects", link: "#"},
    ];

    useEffect(() => {
        getProjects();
    }, []);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Projects" breadcrumbItems={breadcrumbItems}/>

                    <Card>
                        <div className="px-4 py-4">

                            <Row className="justify-content-end mb-4">
                                <Col md={2}>
                                    <Link to="/terra/projects/add" className="btn btn-primary btn-rounded" block color="primary"> <i className="fas fa-plus"/> Add New</Link>
                                </Col>
                            </Row>

                            <Table responsive>
                                <thead className="table-light">
                                <tr>
                                    <th>{props.t('Title')}</th>
                                    <th>{props.t('Ubication')}</th>
                                    <th>{props.t('Available CU')}</th>
                                    <th>{props.t('Blocks')}</th>
                                    <th>{props.t('Actions')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    projects.map(project => (
                                      <tr>
                                          <td><Link to={'/terra/projects/' + project.id }>{ project.name }</Link></td>
                                          <td>{ project.ubication.city + ', ' + project.ubication.country }</td>
                                          <td>{ project.current.available_capital_units } / { project.capital_units }</td>
                                          <td>{ project.blocks ? project.blocks.length : 0}</td>
                                          <td>
                                              <div className="btn-group">
                                                  <Row>
                                                      <Col md={6}>
                                                          <i className="fas fa-edit text-primary"></i>
                                                      </Col>
                                                      <Col md={6}>
                                                          <i className="fas fa-trash text-danger"></i>
                                                      </Col>
                                                  </Row>
                                              </div>
                                          </td>
                                      </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </div>
                    </Card>

                </Container>
            </div>
        </>
    )
}

export default withNamespaces()(Projects);
