import React, {useEffect, useState} from "react";
import {Button, Col, Row, Table} from "reactstrap";
import Create from "./Components/Create";
import {withNamespaces} from "react-i18next";
import {axiosApi} from "../../../../../helpers/Api";
import {formatDate} from "../../../../../utils/format";
import {useSelector} from "react-redux";
import Select from "react-select";
import StatusChanger from "./Components/StatusChanger";
import ChangeStatus from "./Components/ChangeStatus";

const SignalsSection = (props) => {

    const {user} = useSelector((state) => state.user);

    const [signal, setSignal] = useState({});

    const [signals, setSignals] = useState([]);

    const [create, setCreate] = useState(false);
    const toggle = () => setCreate(!create);

    const [changeState, setChangeState] = useState(false);
    const toggleChangeState = (s) => {
        setSignal(s);
        setChangeState(!changeState)
    };

    /**
     * @desc Method to get the created signals.
     * @returns {Promise<void>}
     */
    const getSignals = async () => {
        await axiosApi.get(process.env.REACT_APP_SIGNALS_API + 'api/signals' + (user.type.name === 'Educator' ? `/${user.id}` : ''))
            .then(response => {
                setSignals(response.data);
            })
            .catch(error => console.error(error));
    }

    /**
     * @desc Method to delete signal.
     * @param id
     * @returns {Promise<void>}
     */
    const deleteSignals = async (id) => {
        await axiosApi.delete(process.env.REACT_APP_SIGNALS_API + 'api/signals/' + id)
            .then(response => {
                getSignals();
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        getSignals();
    }, []);

    return (
        <div>
            <Row className="justify-content-end mb-4">
                <Col md={2} className="text-end">
                    <Button onClick={toggle} className="btn-rounded" block color="primary"> <i
                        className="fas fa-plus"/> Add New</Button>
                </Col>
            </Row>

            <Table responsive>
                <thead className="table-light">
                <tr>
                    <th>{props.t('Market')}</th>
                    <th>{props.t('Asset')}</th>
                    <th>{props.t('Take Profits')}</th>
                    <th>{props.t('Date')}</th>
                    <th>{props.t('Status')}</th>
                    <th>{props.t('')}</th>
                    <th>{props.t('Actions')}</th>
                </tr>
                </thead>
                <tbody>
                {
                    signals.map(signal =>
                        <tr>
                            <td>{signal.market.name}</td>
                            <td>{signal.asset.asset}</td>
                            <td>{signal.take_profits.length}</td>
                            <td>{formatDate(signal.created_at)}</td>
                            <td>{signal.status}</td>
                            <td>
                                <a onClick={() => toggleChangeState(signal)} className="btn btn-primary">Actualizar Estado</a>
                            </td>
                            <td className="text-center">
                                <i onClick={() => deleteSignals(signal.id)} style={{cursor: "pointer"}}
                                   className="fas fa-trash text-danger"></i>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </Table>

            <Create toggle={toggle} isOpen={create} getSignals={getSignals}/>

            {
                signal ?
                    <ChangeStatus isOpen={changeState} toggle={toggleChangeState} signal={signal} getSignals={getSignals} /> : <></>
            }
        </div>
    )
}

export default withNamespaces()(SignalsSection);
