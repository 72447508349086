import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {withNamespaces} from "react-i18next";
import {Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {axiosApi} from "../../../helpers/Api";
import classnames from "classnames";
import General from "./Sections/General";
import Blocks from "./Sections/Blocks";
import Multimedia from "./Sections/Multimedia";

const Project = (props) => {

    let {id} = useParams();

    const [project, setProject] = useState({});
    const [loading, setLoading] = useState(true);

    const getProject = () => {
        axiosApi.get(process.env.REACT_APP_AGRONOMY_URL + 'api/projects/' + id)
            .then(response => {
                setProject(response.data.project);
                setLoading(false);
            });
    }

    let breadcrumbItems = [
        {title: "Odin", link: "/"},
        {title: "Agronomy", link: "/agronomy"},
        {title: "Projects", link: "/agronomy/projects"},
        {title: "Project", link: "#"},
    ];

    const [tabHandler, setTabHandler] = useState("general");
    const changeTab = (id) => setTabHandler(id);

    useEffect(() => {
        getProject();
    }, []);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    {
                        loading ? <></> :
                            <>
                                <Breadcrumbs title="Project" breadcrumbItems={breadcrumbItems}/>

                                <h5 className="text-secondary">Project:</h5>
                                <h3 className="text-primary">{project.name}</h3>
                                <hr/>

                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Nav tabs className="nav-tabs-custom nav-justified">
                                                <NavItem>
                                                    <NavLink
                                                        style={{cursor: "pointer"}}
                                                        className={classnames({
                                                            active: tabHandler === "general"
                                                        })}
                                                        onClick={() => {
                                                            changeTab("general");
                                                        }}
                                                    >
                                                        <span className="d-sm-block">General</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{cursor: "pointer"}}
                                                        className={classnames({
                                                            active: tabHandler === "media"
                                                        })}
                                                        onClick={() => {
                                                            changeTab("media");
                                                        }}
                                                    >
                                                        <span className="d-sm-block">Multimedia</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{cursor: "pointer"}}
                                                        className={classnames({
                                                            active: tabHandler === "blocks"
                                                        })}
                                                        onClick={() => {
                                                            changeTab("blocks");
                                                        }}
                                                    >
                                                        <span className="d-sm-block">Blocks</span>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>

                                            <TabContent activeTab={tabHandler}>
                                                <TabPane tabId="general" className="pt-3">
                                                    <General project={project}/>
                                                </TabPane>
                                                <TabPane tabId="media" className="p-3">
                                                    <Multimedia project={project} />
                                                </TabPane>
                                                <TabPane tabId="blocks" className="p-3">
                                                    <Blocks project={project}/>
                                                </TabPane>
                                            </TabContent>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                    }
                </Container>
            </div>
        </>
    )
}

export default withNamespaces()(Project);
