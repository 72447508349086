import React from "react";

const Pagination = ({pages, getMethod}) => {
    return (
        <nav className="pagination-rounded" aria-label="Page navigation example">
            <ul className="justify-content-center pagination">
                {
                    pages.map((page, index) => (
                        index === 0 ?
                            <li onClick={() => getMethod(page.url)} className="page-item"><a href="#" tabIndex="-1" className="page-link">Previous</a></li> :
                            index === pages.length - 1 ? <li onClick={() => getMethod(page.url)} className="page-item"><a href="#" className="page-link">Next</a></li> :
                                <li onClick={() => getMethod(page.url)} className={'page-item ' + (page.active ? 'active' : '')}><a href="#" className="page-link">{page.label}</a></li>
                    ))
                }
            </ul>
        </nav>
    )
}
export default Pagination;
