import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Col, Nav, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import PersonalData from "./Components/PersonalData";
import BillingInfo from "./Components/BillingInfo";
import BankInfo from "./Components/BankInfo";
import TetherWallet from "./Components/TetherWallet";
import Password from "./Components/Password";
import Kyc from "./Components/Kyc";
import {useSelector} from "react-redux";

const Data = (props) => {

    const {user} = useSelector((state) => state.user);

    const [customActiveTab, setCustomActiveTab] = useState("1");
    const toggleCustom = (id) => setCustomActiveTab(id);

    return (
        <Row>
            <Col md={2}>
                <Nav pills className="flex-column" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <NavLink id="v-pills-home-tab" style={{cursor: "pointer"}}
                             className={classnames({
                                 active: customActiveTab === "1"
                             }, "mb-2")}
                             onClick={() => {
                                 toggleCustom("1");
                             }} aria-controls="v-pills-home" aria-selected="true">
                        Personal
                    </NavLink>
                    <NavLink id="v-pills-profile-tab" style={{cursor: "pointer"}}
                             className={classnames({
                                 active: customActiveTab === "2"
                             }, "mb-2")}
                             onClick={() => {
                                 toggleCustom("2");
                             }} aria-controls="v-pills-home" aria-selected="true">
                        Billing
                    </NavLink>
                    <NavLink id="v-pills-messages-tab" style={{cursor: "pointer"}}
                             className={classnames({
                                 active: customActiveTab === "3"
                             }, "mb-2")}
                             onClick={() => {
                                 toggleCustom("3");
                             }} aria-controls="v-pills-home" aria-selected="true">
                        Bank
                    </NavLink>
                    <NavLink id="v-pills-messages-tab" style={{cursor: "pointer"}}
                             className={classnames({
                                 active: customActiveTab === "4"
                             }, "mb-2")}
                             onClick={() => {
                                 toggleCustom("4");
                             }} aria-controls="v-pills-home" aria-selected="true">
                        KYC
                    </NavLink>
                    {
                        user.type.name === 'Superadmin' ?
                            <NavLink id="v-pills-messages-tab" style={{cursor: "pointer"}}
                                     className={classnames({
                                         active: customActiveTab === "5"
                                     }, "mb-2")}
                                     onClick={() => {
                                         toggleCustom("5");
                                     }} aria-controls="v-pills-home" aria-selected="true">
                                Password
                            </NavLink> : <></>
                    }
                    <NavLink id="v-pills-settings-tab" style={{cursor: "pointer"}}
                             className={classnames({
                                 active: customActiveTab === "6"
                             })}
                             onClick={() => {
                                 toggleCustom("6");
                             }} aria-controls="v-pills-home" aria-selected="true">
                        Tether
                    </NavLink>
                </Nav>
            </Col>
            <Col md={10}>
                <TabContent activeTab={customActiveTab} className="text-muted mt-4 mt-md-0" id="v-pills-tabContent">
                    <TabPane tabId="1" role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <PersonalData user={props.user} />
                    </TabPane>
                    <TabPane tabId="2" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                        <BillingInfo user={props.user} />
                    </TabPane>
                    <TabPane tabId="3" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                        <BankInfo user={props.user} />
                    </TabPane>
                    <TabPane tabId="4" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                        <Kyc user={props.user} />
                    </TabPane>
                    <TabPane tabId="5" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                        <Password user={props.user} getUser={props.getUser} />
                    </TabPane>
                    <TabPane tabId="6" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                        <TetherWallet user={props.user} />
                    </TabPane>
                </TabContent>
            </Col>
        </Row>
    )
}

export default withNamespaces()(Data);
